.moji-oglasi-content {
	@extend .registracija-content;
	border-top: 0;
	padding: 0;
	min-height: 1000px;
	flex-direction: column;
	justify-content: flex-start;

	.oglasi-content-text__no-ads-text {
			text-align: center;
			padding-top: 35px;
			font-size: 30px;
	}
}

.moji-oglasi-item {
	width: 100%;
	border-bottom: 1px solid #d9d9d9;
	padding: 3px;
	position: relative;

	.moji-oglasi-item-img {
		span {
			display: none;
		}
	}

	.placen-oglas-vreme {
			visibility: hidden;
	}

	&.moji-oglasi-placen-oglas {
			.moji-oglasi-item-img {
					span {
							display: block;
					}
			}

			.placen-oglas-vreme {
					visibility: visible;
			}
	}
}

.moji-oglasi-item-img {
	position: relative;
	float: left;
	width: 168px;

	@include for-desktop {
			width: 218px;
	}
}

.moji-oglasi-item-content {
	float: left;
	width: 75%;
	padding: 2px 0px 0px 10px;

	&-main {
			display: flex;
			justify-content: space-between;
			overflow: hidden;
			padding-bottom: 6px;
	}

	.moji-oglasi-item-heading {
			font-family: 'Tahoma';
			font-size: 18px;
			font-weight: bold;
			color: $color-text;

			@include hover-active-focus {
					color: $color-primary;
			}
	}
}

.moji-oglasi-item-content-info {
	font-family: 'Trebuchet MS';
	color: #999;
	font-weight: bold;
	width: 60%;
	@include for-desktop {
			width: 65%;
	}
	text-overflow: ellipsis;
	white-space: nowrap;
	// overflow: hidden;

	.cena,
	.cena-akcija,
	.vrsta-cene {
			font-size: 16px;
			margin-right: 8px;
			margin-top: 2px;
	}

	.cena {
			margin-left: 3px;
	}

	.vrsta-cene {
			display: inline-block;
			color: $color-text;
	}
}

.moji-oglasi-item-content-vreme {
	text-align: right;
	font-size: 13px;
	color: #333;
	padding-right: 5px;

	.placen-oglas-vreme {
			color: #e91c23;
	}

	i {
			margin-left: 5px;
			font-size: 15px;
			vertical-align: top;
	}

	p {
			margin-bottom: 5px;

			&:last-of-type {
					margin-bottom: 7px;
			}

			&:nth-of-type(2) {

					i {
							font-size: 9px;
							margin-right: -1px;
					}
			}
	}

}

.moji-oglasi-item-content-akcije {
	float: left;
	width: 100%;
	display: flex;

	.moji-oglasi-akcije-item {
		flex-grow: 1;
		margin-right: 4px;

		i {
			font-size: 18px;
			margin-right: 2px;
		}

		a {
			width: 100%;
			color: $color-text;
			background: #eee;
			border: 1px solid #d9d9d9;
			padding: 3px 10px;
			text-align: center;
			font-family: 'Trebuchet MS';
			font-weight: bold;

			@include hover-active-focus {
				color: $color-white;
				background: $color-primary;
			}
		}
	}

	.moji-oglasi-akcije-item.disabled {
		a {
			color: #d9d9d9;
			cursor: not-allowed;

			&:hover {
				background: #eeeeee;
				color: #d9d9d9;
			}
		}
	}

	.moji-oglasi-akcije-item.nastavi {
		a {
			color: white;
			background: #e91c23;
		}
	}

}