.filter-buttons {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: space-between;
	margin-bottom: 1%;
	margin-top: 10px;

	&-toggle-detail,
	&-search {
		width: 49%;
		font-size: 20px;
		padding: 8px;
		border-radius: 4px;
		text-align: center;
		font-family: 'Open Sans', sans-serif;
		display: flex;
		justify-content: center;
		align-items: center;

		font-size: 25px;
		height: 3rem;


		i {
			font-size: 1.5rem;
			margin-right: 6px;
		}

		span {
			font-size: 1rem;
			padding-top: 1px;
		}

		@include for-mobile-and-tablet {
			width: 100%;
			margin-bottom: 10px;
		}
	}

	&-toggle-detail {
		background-color: $color-white;
		color: $color-text;
		outline: none;
		border: 1px solid $color-primary;
		cursor: pointer;

		font-weight: bold;
		color: $color-primary;
		border-radius: 10px;

		&__disabled {
			opacity: .3;
			cursor: not-allowed;
		}
	}

	&-search {
		background-color: $color-primary;
		color: $color-white;
		border: 1px solid #0050A0;
		cursor: pointer;

		font-weight: bold;
		border-radius: 10px;
	}

	.disabled {
		opacity: .8;
		cursor: not-allowed;
	}

	@include for-mobile-and-tablet {
		// margin: .5rem;
	}
}

.filter-navigation {
	display: flex;
	width: 100%;
	justify-content: space-between;

	&-item {
		width: 50%;
		border: 1px solid #eeeeee;
		padding: .5rem 15px;
		display: flex;

		a {
			display: flex;
		}

		&-text {
			display: flex;
			flex-direction: column;
			margin-left: .75rem;

			p {
				text-transform: uppercase;
				line-height: normal;
				color: $color-grey-dark;

				&:first-of-type {
					font-weight: 700;
					font-size: 1rem;
				}

				&:nth-of-type(2) {
					font-size: 0.9rem;
				}
			}
		}

		&.active {
			background-color: #ddd;
		}
	}
}