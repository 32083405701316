.discount-card, .partner-details-item {
	.card {
		width: 100%;
		background-color: #003263;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;

		&-buttons {
			display: flex;
			flex-direction: column;
			margin-right: 10%;

			button {
				width: 14.5rem;
				height: 2.3rem;
				margin: .7rem 0;
				border: 1px solid #d9d9d9;
				border-radius: .2rem;
				text-transform: uppercase;
				background-color: $color-white;
				font-family: 'Trebuchet MS';
				font-size: 18px;
				font-weight: bold;
				color: rgb(148, 148, 148);
				cursor: pointer;

				@include hover-active-focus {
					background: #ddd;
					border: 1px solid #c0c0c0;
				}

				@include for-mobile-and-tablet { 	width: 100%; }
			}

			@include for-mobile-and-tablet {
				width: 100%;
				margin-right: 0;
				margin: 1%;
				margin-top: 1rem;
			}
		}

		@include for-mobile-and-tablet {
			flex-wrap: wrap;
			margin-left: 2px;
		}
	}

	.info {
		position: absolute;
		font-family: 'Open Sans';
		color: white;
		text-transform: uppercase;
		font-weight: bold;
		cursor: default;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		@include for-laptop-and-desktop {
			width: 340px;
			left: 63px;
			height: 75px;
			top: 160px;
		}

		@include for-mobile-and-tablet {
			top: 37vw;
    	left: 11vw;
		}

		p {
			line-height: 100%;
		}

		&-user {
			font-size: 12px;
			@include for-mobile-and-tablet {
				font-size: 4vw;
			}
		}

		&-number {
			margin: 0;
			font-family: inherit;
			@include for-laptop-and-desktop {
				font-size: 25px;
			}

			@include for-mobile-and-tablet {
				font-size: 5.5vw;
			}
		}

		&-validity-period {
			margin-top: 7px;

			@include for-mobile-and-tablet {
				margin-top: 1.5vw;
				font-size: 4vw;
			}

			span {
				&:last-of-type {
					@include for-laptop-and-desktop {
						margin-left: 49px;
					}
					@include for-mobile-and-tablet {
						margin-left: 7vw;
					}
				}
			}
		}
	}

	.qr-code {
		position: absolute;
		width: 56px !important;
		height: 56px !important;

		@include for-laptop-and-desktop {
			top: 37px;
			left: 326px;
		}

		@include for-mobile-and-tablet {
			width: 13.5vw !important;
			height: 13.5vw !important;
			top: 9vw;
			right: 10vw;
		}
	}

	.activate {
		position: absolute;
		background-color: #003263;
		width: 62%;
		height: 252px;
		color: $color-white;
		text-align: center;
		opacity: .9;

		img {
			@include for-laptop-and-desktop {
				position: absolute;
				left: 12px;
				top: 85px;
			}

			@include for-mobile-and-tablet {
				position: absolute;
				z-index: -1;
				top: -25px;
				left: 0;
				right: 0;
				margin-left: auto;
				margin-right: auto;
			}
		}

		p {
			line-height: normal;
			letter-spacing: 2px;
		}

		&-link {
			position: absolute;
			font-weight: bold;
			cursor: pointer;

			@include for-laptop-and-desktop {
				top: 104px;
				left: 30px;
				width: 370px;
			}

			@include for-mobile-and-tablet {
				top: 50%;
				left: 5px;
				right: 5px;
			}
		}

		@include for-mobile-and-tablet {
			top: 0;
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: center;
			height: 250px;
		}
	}

	.partners-list-container {
		border: 1px solid #c0c0c0;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
	}

	.partners-item {
		width: 100%;
    border-bottom: 1px solid #d9d9d9;
    padding: 3px;
    position: relative;
		display: flex;

		@include for-mobile-and-tablet {
			flex-direction: column;
		}
	}

	.partners-item-img-wrapper {
		@include for-laptop-and-desktop { cursor: pointer; }
	}

	.partners-item-container {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 4px;

		@include for-mobile-and-tablet {
			flex-direction: column;
		}
	}

	.partners-item-slika-container {
		position: relative;
		width: 40%;

		@include for-mobile-and-tablet {
			width: 100%;
			height: 35vh;
		}
	}
	
	.partners-item-slika {
		@include for-laptop-and-desktop {
			width: 200px;
			height: 150px;
			background: no-repeat center center;
			background-size: cover;
		}

		@include for-mobile-and-tablet {
			background: no-repeat center center;
			background-size: contain;
		}

		img { width: 100%; height: 100%; }
	}

	.partners-item-contact {
		p {
			display: flex;
			height: 25px;
		}
	}

	.partners-item-discount {
		display: flex;
		flex-direction: column;
		font-weight: bold;
		font-size: 1.2rem;
		text-transform: uppercase;
		text-align: center;
		height: 118px;
		justify-content: start;

		span {
			background-color: red;
			padding: 7px;
			color: $color-white;
			min-width: 150px;
		}
		
		a {
			background-color: #999999;
			padding: 7px;
			text-decoration: none;
			text-transform: uppercase;
			color: $color-white;
			margin-top: .3rem;
		}

		@include for-mobile-and-tablet { height: auto; }
	}

	.partners-filter {
		width: 100%;
		border: 1px solid #c0c0c0;
		padding: 15px;

		.heading {
			line-height: 120%;
			color: #333;
			font-family: 'Trebuchet MS';
			font-weight: bold;
			text-transform: uppercase;
		}

		&-fields {
			display: flex;
			@include for-mobile-and-tablet { flex-wrap: wrap; }

			.form-group {
				width: 30%;
				margin-top: 20px;
				margin-right: 12px;

				@include for-mobile {
					width: 100%;
					margin-right: 0;
				}

				@include for-tablet { width: 25%; }

				label {
					display: inline-block;
				}
				
				select {
					height: 38px;
					width: 100%;
				}

				input {
					display: inline-block;
					width: 100%;
					background: #f9f9f9;
					border: 1px solid #c0c0c0;
					border-radius: 3px;
					padding: 10px;
				}

				&:nth-of-type(3) {
					@include for-mobile { width: 85%; }
					@include for-tablet { width: 45%; margin-right: 0; }
				}
			}

			button {
				position: relative;
				padding: 3px 6px 4px 7px;
				margin-left: -14px;
				height: 38px;
				background: $color-bg;
				border: 0;
				top: 20px;
				cursor: pointer;

				@include for-mobile { margin-left: 0; width: 15%; }
				@include for-tablet { margin-left: -76px; width: 10%; }
			}
		}
	}
}

.discount-card-verification {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 1rem;
	margin-left: 7rem;
	width: 30rem;
	height: 7rem;
	border: 1px solid #ddd;
	border-radius: .3rem;
	color: $color-white;
	text-align: center;

	&.verified {
		background: #15b500;
	}

	&.not-verified {
		background: #e91c23;
	}

	h1 {
		text-transform: uppercase;
		padding: .3rem;
		margin: 0;
	}
}

.partner-details {
	&-container {
		border: 1px solid #c0c0c0;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
	}

	&-menu {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: space-between;
		padding: .1rem;

		button {
			width: 33%;
			font-family: 'Trebuchet MS';
			font-size: 14px;
			font-weight: bold;
			border: 0;
			background: #ddd;
			color: $color-text;
			padding: 5px;
			text-transform: uppercase;
			cursor: pointer;

			&.active {
				color: $color-white;
				background: $color-primary;
			}

			@include hover-active-focus {
				color: $color-white;
				background: $color-primary;
			}
		}
	}

	&-main {
			padding: .3rem;

			.location {
				border: 1px solid #c0c0c0;
				margin-bottom: 1rem;

				.header {
					text-align: center;
					text-transform: uppercase;
				}

				.info, .worktime {
					padding: .3rem;
					position: relative;
					
					&.with-map {
						margin-bottom: 17rem;
					}
				}

				.worktime {
					display: flex;
					justify-content: space-around;
					text-align: center;
					// margin-top: 12rem;

					.hours {
						border: 1px solid #ddd;
						padding: .2rem;
						width: 15%;

						p {
							&:first-child {
								border-bottom: 1px solid #ddd;
							}
						}
					}
				}
			}

			.partner-ads-btn {
				width: 100%;
				background-color: #11a73f;
				color: $color-white;
				font-weight: bold;
				text-align: center;
				text-transform: uppercase;
				padding: 10px;
				border-radius: 2px;

				@include hover-active-focus {
					background-color: $color-primary;
				}
			}
	}
}

.discount-card-price {
	display: flex;
	margin-top: .3rem;
	font-family: 'Tahoma';
	font-size: 18px;
	padding: 2px 6px;
	padding: 0;
	font-weight: bold;

	img {
		margin-right: .3rem;
	}

	&-small {
		margin-left: auto;
		margin-right: 10px;

		img {
			width: 15px;
		}

		span {
			font-family: 'Trebuchet MS';
			color: #101010;
			font-weight: bold;
			display: flex;
			align-items: center;
			padding: 1px 4px;
			position: relative;
		}
		p {
			font-size: 9px;
			color: #999999;
			line-height: 80%;
			text-transform: uppercase;

			@include for-laptop-and-desktop { font-size: 11px; }
		}
	}
}

.discount-card-mobile {
	img {
		position: relative;
		width: 100vw;
	}
}
