.category-items {
	display: flex;
	flex-direction: row;
}

.kategorije-item {
	height: 75px;
	background: #ddd;
	position: relative;
	text-align: center;
	margin-right: 1px;
	overflow: hidden;


	&.active {
		i {
			color: $color-white;
		}

		.kategorije-item-icon {
			background: $color-text;
		}

		.kategorije-item-icon__parts {
			.fil0 {
				fill: $color-white;
			}
		}

		.kategorije-item-text {
			background: $color-primary;
			color: $color-white;

			&--parts {
				background-color: $color-secondary;
			}
		}
	}

	@include hover-active-focus {
		i {
			color: $color-white;
		}

		.kategorije-item-icon {

			background: $color-text;


		}

		.kategorije-item-icon__parts {
			.fil0 {
				fill: $color-white;
			}
		}

		.kategorije-item-text {
			background: $color-primary;
			color: $color-white;

			&--parts {
				background-color: $color-secondary;
			}
		}
	}

	&.disabled {
		i {
			color: $color-text;
		}

		.kategorije-item-icon {
			background: $color-white;
		}

		.kategorije-item-text {
			background: #c0c0c0;
			color: $color-text;
		}
	}

	a {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
	}

	i {
		font-size: 40px;
		position: relative;
	}

	a {
		color: $color-text;
	}
}

.kategorije-item-icon {
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: center;

	&__parts {
		width: 50px;
		height: auto;
	}
}

.kategorije-item-text {
	background: #c0c0c0;
}


.kategorije-sub {
	padding: 10px 20px 0 20px;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.kategorije-sub-item {
	flex-grow: 1;
	text-align: center;

	&.active {
		a {
			color: $color-primary;
		}
	}

	a {
		color: $color-text;

		@include hover-active-focus {
			color: $color-primary;

			.shop-category-icon {
				.fil0 {
					fill: $color-primary;
				}

				fill: $color-primary;
			}
		}
	}

	i {
		font-size: 25px;
	}

	p {
		white-space: nowrap;
	}

	&.disabled {
		a {
			@include hover-active-focus {
				color: $color-text;
			}
		}
	}
}