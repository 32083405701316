.saved-profiles {
	@include for-mobile-and-tablet { 
		overflow: auto;
	}

	.custom-select {
		@include for-mobile { 
			width: 100%;
		}

		@include for-tablet { 
			width: 50%;
		}
	}

	.sacuvani-korisnici-item {
		@include for-mobile { 
			width: 48.5%;
		}
	}
}