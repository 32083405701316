.ads-list {
	overflow: hidden;
}

.oglasi {
	width: 100%;
	position: relative;
	min-height: 100px;

	.oglasi-header {
		margin-top: 3px;
	}
}

.oglasi-header {
	@extend .sidebar-header;
}

.oglasi-filter {
	margin-top: 3px;
	border: 1px solid #d9d9d9;
	background: #f9f9f9;
}

.oglasi-filter-content {
	float: left;

	&_item,
	i {
		color: #777;
	}

	i {
		font-size: 22px;
		margin-right: 3px;
	}

	&_item {
		display: inline-block;
		cursor: pointer;
		padding: 4px 7px;

		@include hover-active-focus {
			color: $color-primary;

			i {
				color: $color-primary;
			}
		}
	}

	&_item.active,
	.active i {
		color: $color-primary;
	}

	li {
		border-right: 1px solid #d9d9d9;
		margin: 0;
	}
}

.oglasi-filter-grid {
	width: 119px;
	float: right;

	i {
		margin: 0;
		color: #d9d9d9;
		font-size: 22px;
	}

	.active {
		i {
			color: #777;
		}
	}

	li {
		border-left: 1px solid #d9d9d9;
		border-right: none;
		position: relative;

		@include hover-active-focus {
			.tooltip {
				display: block;
			}
		}

		&:last-of-type {

			i {
				color: #777;
			}

			@include hover-active-focus {
				i {
					color: $color-text;
				}
			}

			.active i {
				color: $color-secondary;
			}

		}
	}

	&_item {
		display: inline-block;
		padding: 4px 5px 4px 9px;
		cursor: pointer;
	}
}

.oglasi-content {
	width: 100%;
	position: relative;
	overflow: hidden;
}

.oglasi-content-text {
	display: flex;
	flex-wrap: wrap;
	width: 100%;

	&__loading-text {
		font-size: 20px;
		padding: 20px 10px 10px 10px;
		float: left;
		width: 100%;
		text-align: center;
	}
}

.oglasi-content-text_no-ads,
.profile-content-text_no-content {
	text-align: center;
}

.oglasi-content-text__no-ads-text,
.profile-content-text__no-content-text {
	font-size: 36px;
	padding: 10px;
	height: 105px;
	line-height: 80px;

	@include for-mobile-and-tablet {
		line-height: normal;
	}
}

.oglasi-content-list {
	display: none; // REMOVE THIS
}

.oglasi-content-list {
	float: left;
	width: 100%;

	&__loading-text {
		font-size: 20px;
		padding: 20px 10px 10px 10px;
		float: left;
		width: 100%;
		text-align: center;
	}
}

.oglasi-content-list_no-ads {
	text-align: center;
}

.oglasi-item-list {
	float: left;
	width: 100%;
	padding: 3px;
	border-bottom: 1px solid #d9d9d9;
	border-left: 1px solid #d9d9d9;
	border-right: 1px solid #d9d9d9;
	position: relative;
}

.oglasi-item-list-img {
	width: 168px;
	height: 126px;

	@media (min-width: 1199px) {
		width: 218px;
		height: 163.5px;
	}

	float: left;
	position: relative;
}

.oglasi-item-list-content {
	float: left;
	margin-left: 15px;

	.oglasi-item-ikonice-icons {
		font-size: 18px;
	}

	.oglasi-item-heading {
		font-size: 18px;
		margin-left: 0;
		max-width: 420px;
	}

	.oglasi-vreme {
		position: absolute;
		right: 10px;
		top: 5px;
	}

	.icons-item {
		text-align: left;
		padding-right: 10px;

		&:not(:first-child) {
			padding-left: 10px;
		}

		&::after {
			top: 0px;
		}

		p {
			display: inline-block;
		}
	}

	.oglasi-items-list-akcije {
		background: #d9d9d9;
		position: absolute;
		width: 170px;
		right: 0;
		bottom: 0;
		padding: 0 13px;

		p {
			display: inline-block;
			vertical-align: middle;
		}

		&::before {
			content: '';
			position: absolute;
			left: -4px;
			top: 0;
			width: 0;
			height: 0;
			border-bottom: 21px solid #d9d9d9;
			border-left: 4px solid transparent;
		}

		a {
			color: $color-text;
			margin-bottom: 0;

		}

		.akcija-sacuvaj {

			@include hover-active-focus {

				.star {

					i {
						color: #ff7800;

					}
				}

				.heart {

					i {
						color: #ee0d0d;

					}
				}
			}

			span {
				color: $color-white;

				&.active {
					color: #ee0d0d;
				}
			}
		}

		.star,
		.heart {
			font-size: 15px;
			margin-right: 3px;
			color: $color-white;
		}
	}

	.oglasi-mesto {
		margin-top: 8px;

		i {
			vertical-align: top;
			margin-right: 3px;
		}
	}

}

.oglasi-item-list-mobilni {

	.icons-item {
		width: 80px;

		&:nth-of-type(2) {
			width: 100px;
		}
	}
}

.oglasi-item-list-tablet {

	.icons-item {
		width: 90px;

		&:nth-of-type(2) {
			width: 100px;
		}
	}
}

.oglasi-item-list-tv {

	.icons-item {

		&:nth-of-type(1) {
			width: 90px;
		}

		&:nth-of-type(2) {
			width: 110px;
		}
	}
}

.oglasi-item-list-foto,
.oglasi-item-list-kamera {
	.icons-item {
		&:nth-of-type(2) {
			width: 90px;
		}
	}
}

.oglasi-item-list-ostalo,
.oglasi-item-list-servis {

	.oglasi-item-ikonice-icons {
		margin-top: 10px;
	}

	i {
		vertical-align: top;
	}

	ul {

		display: inline-block;
		margin-left: 5px;

		li {

			&::after {
				content: '/';
				margin-left: 5px;
			}

			&:last-of-type {

				&::after {
					content: '';
				}
			}
		}
	}
}

.oglasi-items-list-cena {
	float: left;
	width: 100%;
	margin-top: 10px;
	font-size: 16px;

	.vrsta-cene {
		font-weight: bold;
		font-size: 16px;
		display: inline-block;
		margin-left: 13px;
	}
}

.oglasi-item-ikonice {
	width: 24.5%;
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	background: #f9f9f9;
	margin-right: 3px;
	margin-top: 3px;

	a {
		display: block;
	}
}

.oglasi-item-tekst {
	@extend .oglasi-item-ikonice;


	@media (min-width: 1199px) {
		width: 24.66%;
	}
}

.oglasi-item-ikonice-img {
	position: relative;
}

.oglasi-item-tekst-img {
	@extend .oglasi-item-ikonice-img;
}

.placen-oglas {
	position: absolute;
	bottom: 5px;
	right: 3px;
	background: url('/img/sprite.png');
	width: 87px;
	height: 14px;
	background-position: 0 -26px;

}

.oglasi-item-heading {
	overflow: hidden;
	color: $color-text;
	font-weight: bold;
	margin-top: 5px;
	margin-left: 5px;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-family: 'Trebuchet MS';
	min-height: 16px;

	@include hover-active-focus {
		color: $color-primary;
	}

	h3 {
		margin: 0;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__full {
		h3 {
			overflow: visible;
			text-overflow: initial;
		}

		white-space: initial;
	}

	&-vehicle-parts {
		h3 {
			min-height: 2rem;
		}
	}
}

.oglasi-item-ikonice-icons {
	height: 35px;
	font-size: 16px;
	font-weight: bold;
}

.oglas-item-slika {
	width: 168px;
	height: 126px;

	@include for-desktop {
		width: 218px;
		height: 163.5px;
	}

	background: no-repeat center center;
	background-size: cover;

	&.detail {
		width: 248px;
		height: 186px;

		@include for-desktop {
			width: 340px;
			height: 255px;
		}
	}

	img {
		width: 100%;
		height: 100%;
	}
}

.oglasi-items-tekst-lokacija {
	@extend .oglasi-items-ikonice-lokacija;

	.oglasi-vreme {
		font-size: 12px;
		padding-top: 4px;
	}
}

.oglasi-items-tekst-akcije {
	@extend .oglasi-items-ikonice-akcije;
}

.oglasi-items-tekst-cena {
	width: 100%;
	margin-left: 8px;
	margin-top: 8px;
	max-height: 20px;
	display: flex;
	align-items: center;
}

.oglasi-item-description {
	height: 51px;

	&_no-specs {
		height: 55px;
	}

	ul {
		margin-left: 5px;
		margin-top: 4px;

		li {
			display: block;
			max-height: 18px;
			font-size: 15px;
			overflow: hidden;
			text-wrap: nowrap;
			text-overflow: ellipsis;
		}
	}

	&_spec-title {
		// display: inline-block;
		font-weight: bold;
		// max-width: 50%;
		// overflow: hidden;
		// text-wrap: nowrap;
		// text-overflow: ellipsis;
		// vertical-align: bottom;
	}

	// &_spec-value {
	// 	vertical-align: bottom;
	// }

	&__inline {
		margin-right: 5px;
		color: #9c9c9c;
		font-weight: bold;
		font-size: 1rem;

		// &:after {
		// 	content: '\00B7';
		// 	margin-left: 5px;
		// }

		&:last-of-type {
			&:after {
				display: none;
			}
		}
	}
}

.oglasi-item-tekst-icons {
	display: flex;
	justify-content: space-around;

	&__item {
		text-align: center;
		text-transform: uppercase;
		font-weight: bold;
	}
}

.icons-item {
	float: left;
	text-align: center;
	margin-top: 7px;
	position: relative;

	&:nth-of-type(3) {
		width: 200px;
	}

	p {
		margin-top: 2px;
	}

	i {
		font-size: 21px;
		vertical-align: top;
	}

	&:first-of-type {
		&::after {
			display: none;
		}
	}

	&::after {
		content: '';
		width: 1px;
		height: 30px;
		background: #d9d9d9;
		position: absolute;
		left: 0;
		top: 6px;
	}

	&:last-of-type {
		border-right: 0;
	}

}

.cena {
	font-family: 'Trebuchet MS';
	background: $color-text;
	color: $color-white;
	font-weight: bold;
	display: inline-block;
	padding: 2px 10px;
	position: relative;

	p {
		white-space: nowrap;
	}

	&::after {
		content: '';
		position: absolute;
		width: 0;
		height: 0;
		top: 0;
		right: -4px;
		border-top: 20px solid #101010;
		border-right: 4px solid transparent;
	}

	&::before {
		content: '';
		position: absolute;
		width: 5px;
		height: 100%;
		background: $color-primary;
		left: -3px;
		top: 0;
	}
}

.stara-cena {
	display: inline-block;
	padding-left: 5px;
	background: #fff;
	font-family: 'Trebuchet MS';
	font-weight: bold;
	color: #9c9c9c;
	text-decoration: line-through;
}

.cena-akcija {
	font-family: 'Trebuchet MS';
	color: #aaa;
	display: inline-block;
	font-weight: bold;
	margin-left: 10px;
	position: relative;

	&::after {
		content: '';
		height: 1px;
		width: 100%;
		position: absolute;
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
		left: 0;
		background: #aaa;
	}
}



.oglasi-items-ikonice-lokacija {
	margin-top: 5px;
	margin-left: 5px;
	width: 95%;
	float: left;

	i {
		margin-right: 3px;
		margin-top: -2px;
	}
}

.oglasi-mesto {
	float: left;
	position: relative;
	padding-right: 0;
	margin-top: 2px;
}

.oglasi-mesto-list {
	float: left;
	background-color: #fff;
	padding-top: 16px;

	span {
		padding-left: 5px;
	}
}

.oglasi-vreme {
	float: right;
}

.oglasi-items-ikonice-akcije {
	@extend .oglasi-items-ikonice-lokacija;
	background: #d9d9d9;
	width: 100%;
	margin-left: 0;
	padding: 4px 7px 1px 7px;

	span {
		color: $color-white;

		&.active {
			color: #ee0d0d;
		}
	}

	label {
		vertical-align: top;
		cursor: pointer;
	}

	.star,
	.heart {
		display: inline-block;
		font-size: 15px;
		margin-top: -8px;
		vertical-align: middle;
	}

	a {
		color: $color-text;

		&.sacuvan-oglas {
			.star {
				i {
					color: #ff7800;
				}
			}
		}

		@include hover-active-focus {

			.star {

				i {
					color: #ff7800;

				}
			}

			.heart {

				i {
					color: #ee0d0d;

				}
			}
		}
	}
}

.akcija-sacuvaj {
	float: left;
}

.akcija-uporedi {
	float: right;
}

.akcija-sacuvaj,
.akcija-uporedi {

	@include hover-active-focus {

		i {
			color: $color-primary;
		}
	}
}

.oglasi-pagination {
	background: #f9f9f9;
	border: 1px solid #d9d9d9;
	width: 100%;
	font-size: 16px;
	padding: 15px;

	ul {
		text-align: center;
	}

	li.active {

		a,
		span {
			background: $color-primary;
			color: $color-white;

			&::after {
				border-top-color: $color-primary;
			}

			&::before {
				border-bottom-color: $color-primary;
			}
		}
	}

	a,
	span {
		background: $color-text;
		color: $color-white;
		padding: 2px 7px;
		position: relative;
		margin-right: 5px;

		@include hover-active-focus {
			background: $color-primary;
			color: $color-white;

			&::after {
				border-top-color: $color-primary;
			}

			&::before {
				border-bottom-color: $color-primary;
			}
		}

		&::after {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			top: 0;
			right: -4px;
			border-top: 23px solid #101010;
			border-right: 4px solid transparent;
		}

		&::before {
			content: '';
			position: absolute;
			left: -4px;
			top: 0;
			width: 0;
			height: 0;
			border-bottom: 23px solid #101010;
			border-left: 4px solid transparent;
		}
	}
}