.online-payment {
	@extend .dodavanje-oglasa-form-podaci;

	&__fields {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;

		.form-group {
			width: 33%;
			margin-right: .5%;

			&:last-of-type {
				margin-right: 0;
			}
		}
	}

	.tip-oglasa-item {
		width: 49.5%;
		margin-right: 1%;

		&:last-of-type {
			margin-right: 0;
		}
	}

	&-app {
		.form-group {
			width: 100%;
			margin-right: 0;
		}

		.tip-oglasa-item {
			width: 48.4%;
			
			margin-right: 4%;

			&:last-of-type {
				margin-right: 0;
			}
		}

		.tip-oglasa-naziv {
			font-size: 24px;
		}
	}

	optgroup {
		color: #a395a4;
		font-weight: 300;

		option {
			color: #666;
		}
	}
}