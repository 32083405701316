
.bank-transfer-link {
	float: left;
	width: 100%;
	font-size: 16px;
	border: 2px dashed #000;
	padding: 10px 30px;
	text-align: center;
	border-radius: 4px;;
	margin-bottom: 10px;

	a {
			background-color: #11a73f;
			border-radius: 4px;
			width: 100%;
			padding: 10px;
			color: #fff;
	}

	h1 {
			color: $color-secondary;
			font-size: 16px;
	}

	p {

	}

	img {
			margin-right: 16px;
	}
}