.vehicle-parts-filter {
	display: flex;
	flex-direction: column;
	background-color: #EEEEEE;
	margin-top: .5rem;
	padding: 1rem 1rem 0.25rem 1rem;
	width: 100%;

	@include for-mobile-and-tablet {
		padding: .5rem;
	}
}

.vehicle-parts-filter-items {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: .4rem;

	.polja-pretrage-item {
		&__gume {
			width: 32%;

			&:nth-child(3n + 3) {
				margin-right: 0;
			}

			&:nth-child(4n + 4) {
				margin-right: 2%;
			}

			@include for-mobile-and-tablet {
				width: 49%;

				&:nth-child(3n + 3) {
					margin-right: 0;
				}

				&:nth-child(4n + 4) {
					margin-right: 2%;
				}

				&:nth-child(2n) {
					margin-right: 2%;
				}
			}
		}

		&__dimenzije {
			@include for-mobile-and-tablet {
				width: 32%;
			}
		}

		&__sezona {
			@include for-mobile-and-tablet {
				margin-right: 0;
			}
		}

		&__proizvodjac {
			@include for-mobile-and-tablet {
				width: 100%;
				margin-right: 0;

				&:nth-child(2n) {
					margin-right: 0;
				}
			}
		}
	}
}

.vehicle-parts-quicksearch-items {
	white-space: nowrap;
	width: 100%;
	overflow-x: auto;
	padding-bottom: 3px;
}

.vehicle-parts-filter-section {
	&__title {
		font-family: 'Open Sans';
		font-size: 1rem;
		font-weight: bold;
		margin-bottom: .5rem;
		text-transform: uppercase;
		text-align: center;

		@include for-mobile-and-tablet {
			margin-top: .5rem;
		}
	}

	&__separator {
		margin: 1rem 0 0.75rem 0;
		height: 1px;
		display: block;
		border:0;
		border-top: 1px solid #999;
	}

	&__count {
		display: flex;
		justify-content: center;
		margin-bottom: .5rem;
		margin-top: .5rem;
	}

	margin-bottom: 1rem;

	.filter-buttons-search {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 0.9rem;
		font-weight: bold;
		height: 3rem;
		border-radius: 10px;

		&:nth-child(1) {
			background-color: $color-white;
			border: 1px solid #999;
			color: #999;
		}

		i {
			font-size: 1.5rem;
			margin-right: 6px;
		}

		span {
			font-size: 1rem;
			padding-top: 1px;
		}

		&:nth-child(2) {
			span {
				margin-right: 7px;
			}
		}

		@include for-mobile-and-tablet {
			width: 49%;
		}
	}

	@include for-mobile-and-tablet {
		margin-bottom: 0;

		.filter-buttons {
			margin: 0;

			.filter-buttons-search {
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 0.9rem;
				font-weight: bold;
				height: 3rem;
				border-radius: 10px;

				&:nth-child(1) {
					background-color: $color-white;
					border: 1px solid #999;
					color: #999;
				}

				i {
					font-size: 1.5rem;
					margin-right: 6px;
				}

				span {
					font-size: 1rem;
					padding-top: 1px;
				}

				&:nth-child(2) {
					span {
						margin-right: 7px;
					}
				}
			}
		}
	}
}

.vehicle-parts-filter-list {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 3px;
	margin: 3px;

	padding-bottom: 3rem;

	&--title {
		color: $color-primary;
		margin: 0.25rem 0;
		text-align: center;
	}

	&--godina {
		gap: .75rem;
	}

	@include for-mobile-and-tablet {
		// margin: 0 0.5rem 0 0.5rem;
		display: grid;
		grid-template-columns: repeat(2, 1fr);

		&--godina {
			padding: .75rem;
		}
	}
}

.vehicle-parts-filter--tip-vozila-table {
	table {
		width: 100%;
		border-collapse: collapse;

		margin-bottom: 3rem;

		th {
			background-color: #f5f5f5;
			border: 1px solid #ddd;
			padding: 0.5rem;
			text-align: center;
		}

		td {
			border: 1px solid #ddd;
			padding: 0.5rem;
			text-align: center;

			a {
				color: $color-text;
			}
		}

		// hove and active
		tr {
			&:hover {
				background-color: #f5f5f5;
			}

			&:active {
				background-color: #f5f5f5;
			}
		}
	}
}

.vehicle-parts-filter--tip-vozila-table-mobile {
	@extend .vehicle-parts-filter--tip-vozila-table;

	margin: 5px;

	table {
		table-layout: fixed;

		thead {
			height: 3rem;
		}

		tr {
			border: 1px solid #DDD;
		}

		td,
		th {
			border: none;

			&:first-child {
				width: 40%;
				word-wrap: break-word;
				overflow: hidden;
				text-overflow: ellipsis;
				font-weight: bold;
			}
		}

		td {
			&:first-child {
				text-align: left;
			}
		}
	}
}

.vehicle-parts-filter-item {
	cursor: pointer;
	position: relative;

	&.active {
		p {
			color: $color-primary;
		}

		border: 1px solid $color-primary;
		cursor: not-allowed;
	}

	&--with-icon {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		border: 1px solid #DDD;

		img {
			width: 4rem;
			height: 4rem;
			object-fit: contain;
			margin-top: 3px;
		}

		p {
			margin-top: auto;
		}

		@include for-mobile-and-tablet {
			/* 	width: 50%; */
			width: 100%;
			margin: 0;
		}
	}

	&--brand {
		@extend .vehicle-parts-filter-item--with-icon;
		width: 100%;
		margin: 0;

		@include for-mobile-and-tablet {
			width: 100%;
		}
	}

	&--text-only {
		/* width: 22.5%; */
		width: 100%;
		padding: .5rem;

		border: 1px solid #ccc;

		@include for-mobile-and-tablet {
			width: 100%;
			text-align: center;
			margin: 0;
		}

		p {
			font-size: 1rem;
		}
	}

	p {
		color: #777;
		font-weight: bold;
		text-align: center;
		padding-left: 3px;
		padding-right: 3px;

		@include for-mobile-and-tablet {
			color: $color-text;
		}
	}
}

.vehicle-parts-filter-item-dimensions {
	width: 30%;

	&--header {
		p {
			font-weight: bold;
		}
	}

	&--content {
		display: flex;
		flex-wrap: wrap;
	}
}

.vehicle-parts-text-search {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	margin: .5rem;
	

	input[type="text"] {
		width: 100%;
		padding: 0.5rem;
		border: 1px solid #ccc;

		height: 3rem;
		border-radius: 6px;
		margin-right: 5px;
	}

	button {
		background-color: $color-primary;
		color: white;
		font-size: 1.25rem;
		border: none;
		cursor: pointer;
		
		min-width: 3rem;
		height: 3rem;
		border-radius: 6px;
		font-size: 25px;
	}
}

.vehicle-parts-quick-search-item {
	display: inline-block;

	height: 2.5rem;
	padding: 9px;
	margin-bottom: .5rem;
	margin-right: .5rem;

	&:last-of-type {
		margin-right: 0;
	}

	border: 1px solid $color-primary;
	border-radius: 10px;

	background-color: $color-white;
	text-align: center;
	color: $color-primary;
	font-size: 1rem;

	@include for-laptop-and-desktop {
		width: 15%;
	}
}

.vehicle-parts-price-sort {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	margin-left: auto;

	p {
		font-size: 1rem;
		font-weight: bold;
	}

	&__buttons {
		display: flex;
		align-items: center;
		justify-content: center;


		img {
			width: 1.5rem;
			height: 1.5rem;
			margin-left: .5rem;
		}
	}

	&__button {
		margin-left: 1rem;

		&.active {
			color: $color-primary;
		}
	}
}

.vehicle-parts-breadcrumbs-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;

	h2 {
		width: 100%;
	}
}

.ads-filter-vehicle-parts {
	@include for-mobile-and-tablet {
		.vehicle-parts-filter {
			margin-top: 0;
		}
	}
}

.pretraga-reset-filter {
	.fil0 {
		fill: #999;
	}
}
