.select-dropdown-mobile {
	height: 90vh;

	&>input[type="text"] {
		width: 100%;
		height: 2.4rem;
		padding: 8px;
		border: 1px solid #777;
		border-radius: 4px;
		outline: none;
		font-size: 6vw;
	}

	.select-dropdown-items-mobile {
		max-height: 90vh;
		overflow-y: auto;
	}

	.select-dropdown-item {
		background-color: white;
		margin: 0.5vh 0.125vh;

		label {
			display: block;
			font-size: 7vw;
		}
	}

	&.select-dropdown-multiple {
		.select-dropdown-items-mobile {
			max-height: 80vh;
			overflow-y: auto;
			padding-bottom: 2.5rem;
		}

		.select-dropdown-item {
			label {
				display: flex;
				padding: 3px;
				align-items: center;
				user-select: none;

				&::before { display: none; }
	
				&::after {
					content: '';
					position: absolute;
					right: 3px;
					width: 1.5rem;
					height: 1.5rem;
					display: inline-block;
					border-radius: 1.5rem;
					padding: 2px;
					background: $color-bg;
					background-clip: content-box;
				}
			}

			input[type="checkbox"] {
				&:hover + label, &:checked + label {
					color: $color-text;
					background-color: $color-white;
				}

				&:checked + label {
					background-color: $color-white;
					&::after { background-color: $color-primary; }
				}
			}
		}

		.select-dropdown-subgroup {
			.select-dropdown-item {
				background-color: $color-bg;
		
				label {
					&::after {
						background: $color-white;
					}
				}

				input[type="checkbox"] {
					&:hover + label, &:checked + label {
						color: $color-text;
						background-color: $color-bg;
					}
	
					&:checked + label {
						background-color: $color-bg;
						&::after { background-color: $color-primary; }
					}
				}
			}

			&-header {
				height: 2.4rem;
				p { font-size: 6vw; }
				
			}
		}

		.select-dropdown-subgroup-header {
			p {
				width: 70%;
			}
	
			.select-all {
				width: 30%;
				font-size: 4.25vw;
			}
		}
	}

	.select-dropdown-group-label {
		position: relative;
		font-size: 6vw;
	}

	.confirm-btn {
		width: 100%;
    border: 0;
		margin-bottom: 0.5rem;
    height: 2.5rem;
    background-color: $color-primary;
    color: white;
    font-size: 1rem;
		position: absolute;
		bottom: 0;
	}
}
