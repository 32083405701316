.polja-pretrage {
	display: flex;
	flex-wrap: wrap;
	width: 100%;

	label {
		font-size: 12px;
		font-family: 'Open Sans';
		display: block;
	}

	.ads-filter__swap {
		width: 100%;
		display: flex;
		flex-wrap: wrap;

		.ads-filter__row {
			margin-top: 0;

			&:last-of-type {
				margin-top: 0
			}
		}
	}

	.ads-filter__row {
		margin-bottom: 5px;
		display: flex;
		flex-wrap: wrap;
		width: 50%;

		.polja-pretrage-item {
				width: 43%;

				&.polja-pretrage-item-vodi-se {
						margin-top: 10px;
				}

				&.fullWidth {
					width: 100%;
					a {
							width: 200%;
							text-align: center;
					}
				}

				&:last-of-type {
						margin-right: 0;
				}
		}
	}
}

.detaljna-polja-pretrage {
	@extend .polja-pretrage;

	.ads-filter__row {

		&:last-of-type {

			margin-top: 15px;

			input,
			label {
					display: inline-block;
					vertical-align: top;
			}
		}
	}
}

.polja-pretrage-item {
	width: 23.5%;
	margin-right: 2%;
	margin-bottom: 8px;
	position: relative;

	label {
		padding-left: .5rem;
		font-size: 14px;
		font-family: 'Open Sans';
		white-space: nowrap;
		display: block;
	}

	.inline-label {
		display: inline-block;
		vertical-align: top;
	}

	.polja-pretrage-input {
		background: white;
		width: 100%;
		outline: none;
		border: 1px solid #777;
		padding: 1rem;
		height: 2.5rem;
		border-radius: 6px;

		font-family: 'Open Sans';
		font-size: 16px;
		font-weight: bold;

		&+.input-addon {
			line-height: 38px;
			height: 38px;
			margin: 1px 1px 0 0;
		}
	}

	&:nth-of-type(4n + 4) {
		margin-right: 0;
	}

	.pretrazi-btn {
		background: $color-text;
		text-transform: uppercase;
		font-size: 20px;
		margin-top: 14px;
		padding: 6px 42px;
		color: $color-white;
		position: relative;
		height: 38px;

		i {
			font-size: 23px;
			margin-left: 8px;
			position: relative;
			top: -2px
		}

		&::before {
				content: '';
				position: absolute;
				left: -5px;
				top: 0;
				width: 0;
				height: 0;
				border-bottom: 38px solid #101010;
				border-left: 5px solid transparent;
		}

		&::after {
				content: '';
				position: absolute;
				width: 0;
				height: 0;
				top: 0;
				right: -5px;
				border-top: 36px solid #101010;
				border-right: 5px solid transparent;
		}

		@include hover-active-focus {
			background: $color-primary;

			&::before {
				border-bottom-color: $color-primary;
			}

			&::after {
				border-top-color: $color-primary;
			}
		}

	}

	::-webkit-input-placeholder {
			color: #666;
			font-family: 'Open Sans';
			font-size: 11px;
	}

	:-moz-placeholder {
			color: #666;
			font-family: 'Open Sans';
			font-size: 11px;
	}

	::-moz-placeholder {
			color: #666;
			font-family: 'Open Sans';
			font-size: 11px;
	}

	:-ms-input-placeholder {
			color: #666;
			font-family: 'Open Sans';
			font-size: 11px;
	}

	label {
		margin-bottom: 2px;
	}

	select,
	.select-with-checkbox label {
			border-radius: 0;
			font-size: 11px;
			font-family: 'Open Sans';
			color: #666;
	}

	.select-with-checkbox {
			.select-dropdown {
					margin: 0;
					margin-top: -3px;
					width: 100%;
			}

			label {
					width: 100%;
					height: 30px;
					padding-top: 6px;

					&::after {
							content: '';
							font-family: 'Open Sans';
							font-size: 11px;
							color: #666;
					}
			}

	}

	.select-dropdown-picker {
		border-radius: 5px;
	}

	&_swapps {
		margin-right: 30px !important;
		&:last-of-type {
			margin-right: 0px !important;
		}
	}

	@include for-mobile-and-tablet {
		&_swapps {
			margin-right: 0px !important;
		}
	}

	&.empty {
		height: 47px;
	}

	&_equipment {
		width: 180px;
	}

	&_equipment:nth-of-type(4n) {
		margin-right: 30px;
	}

	&__half {
		width: 49%;
	}

	@include for-mobile-and-tablet {
		width: 49%;

		&:nth-of-type(2n) {
			margin-right: 0;
		}
	}

	&__half:nth-of-type(2n) {
		margin-right: 0;
	}
}

.input-range-item {
	display: flex;
	align-items: center;
	flex-direction: row;
	background: white;
	border-radius: 5px;
	border: 1px solid #777;
	height: 2.8rem;
	align-self: flex-end;
	background-color: #FAFAFA;
	border: 1px solid #BBB;

}

.polja-pretrage-item__input-range-measure {
	font-family: 'Open sans';
	font-size: 14px;
	color: #666;
	padding-left: 0.15rem;
	padding-right: 0.15rem;
	margin-left: auto;
	background: #ddd;
	display: flex;
	height: 100%;
	width: 2.5rem;
	border-radius: 0 5px 5px 0;
	justify-content: center;
	align-items: center;
}

.polja-pretrage-item__input-range-wrapper, .polja-pretrage-item-cena {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.input-range-item {
		width: 48%;
	}

	label {
		width: 100%;
		white-space: nowrap;
	}
}

.polja-pretrage-item__input-range {
	width: 75%;
	background: rgba(0, 0, 0, 0);
	border: 0;
	padding: 8px 1px;
	font-family: 'Open Sans';
	font-weight: bold;
	font-size: 14px;
	padding-left: 1rem;

	&:last-of-type {
		margin-right: 0;
	}

	&.error {
		border: 1px solid #e91c23;
	}

	&::placeholder, &::-webkit-input-placeholder {
		font-family: 'Open Sans';
		font-size: 14px;
		font-weight: normal;
	}

	&:disabled { opacity: .3; }
}

.polja-pretrage-item__select-range {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;

	.select-range-item {
		width: 48%;

		.select-dropdown-picker {
			background-position: 90%;
		}

		&:nth-of-type(2) {
			margin-top: auto;
		}
	}

	label {
		min-height: 17px;
		white-space: nowrap;
	}
}

.text-search-mobile {
	form {
		width: 100%;
    position: relative;
		min-height: 11rem;
	}

	input {
		width: 100%;
    position: relative;
    height: 2.5rem;
    border: 0;
    border: 1px solid #D3D3D3;
    padding: 0 0 0 0.5rem;
    margin: 0.5rem .25rem;
    height: 3rem;
		border-radius: 6px;
	}

	button {
		min-width: 3rem;
    color: $color-white;
		background-color: $color-primary;
    font-size: 1.3rem;
    height: 3rem;
    margin: 0.5rem 0.25rem 0.5rem 0;
		border: none;
		border-radius: 6px;
	}
}
