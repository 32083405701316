.moji-oglasi-item-img {
	@include for-mobile {
		width: 100%;
		float: none;
	}

	a {
		@include for-mobile-and-tablet {
			display: block;
		}
	}

	.oglas-item-slika {
		@include for-mobile {
			min-height: 15rem !important;
		}
	}

	.ad-status__item {
		@include for-mobile {
			font-size: 1rem;
		}
	}
}

.moji-oglasi-item-content {
	@include for-mobile {
		width: 100%;
		float: none;
	}

	.moji-oglasi-item-heading {
		@include for-mobile {
			white-space: normal;
		}
	}

	&-akcije {
		@include for-mobile {
			float: none;
			flex-wrap: wrap;
		}

		.moji-oglasi-akcije-item {
			@include for-mobile {
				flex-grow: initial;
				width: 100%;
				margin-bottom: 2px;
			}

			margin-right: 0;

			i {
				@include for-mobile {
					font-size: 1rem;
				}
			}

			a {
				@include for-mobile {
					font-size: 1rem;
					padding: .5rem 0;
				}
			}
		}
	}
}