.quick-search {
	margin-top: 3px;

	a {
		width: 25%;
		font-size: 1rem;
		display: inline-block;
		color: $color-text;
	}

	@include for-mobile-and-tablet {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		a {
			font-size: 14px;
			width: 45%;
			margin-bottom: 0.5rem;
		}
	}
}