.novosti-header {
	@extend .page-header;
}

.novosti-content {
	@extend .registracija-content;
	padding: 10px;
}

.novosti-heading {
	margin-bottom: 5px;
	width: 100%;

	.novosti-main-heading {
			font-family: 'Tahoma';
			font-weight: bold;
			font-size: 38px;
			color: $color-primary;
			line-height: 110%;
			margin-bottom: 5px;
	}
}

.novosti-item {
	float: left;
	width: 100%;

	&::after {
			content: '';
			height: 1px;
			width: 100%;
			display: block;
			background: #bbb;
			margin: 5px auto 10px auto;
			float: left;
	}

	&:last-of-type {
			&::after {
					display: none;
			}
	}
}

.novosti-item-img {
	float: left;
	width: 200px;
	position: relative;

	.ima-foto {
			background: url('/img/sprite.png') no-repeat;
			width: 80px;
			height: 25px;
			background-position: 0 -52px;
			position: absolute;
			bottom: 5px;
			left: -4px;
	}

	.ima-video {
			background: url('/img/sprite.png') no-repeat;
			width: 80px;
			height: 25px;
			background-position: 0 -77px;
			position: absolute;
			bottom: 5px;
			left: -4px;
	}
}

.novosti-item-content {
	float: left;
	width: 456px;
	margin-left: 10px;
	color: #666;
}

.novosti-item-heading {
	a {
			font-family: 'Tahoma';
			font-size: 22px;
			font-weight: bold;
			color: $color-text;

			@include hover-active-focus {
					color: $color-primary;
			}
	}

	.heading-lead {
			font-size: 16px;

			span {
					display: inline-block;
					vertical-align: middle;
					margin-right: 14px;
			}

			i {
					vertical-align: top;
					font-size: 14px;
			}
	}
}

.novosti-item-text {
	a {
			color: $color-text;
			font-size: 12px;
			margin-left: 3px;
			vertical-align: bottom;

			@include hover-active-focus {
					color: $color-primary;
			}
	}
}

.novosti-item-kategorije {
	margin-top: 5px;

	a {
			background: #999;
			color: $color-white;
			text-transform: uppercase;
			font-size: 13px;
			letter-spacing: 1px;
			padding: 0px 2px;
			position: relative;
			margin-left: 13px;
			display: inline-block;
			height: 14px;
			line-height: 100%;

			@include hover-active-focus {
					background: #888;

					&::before {
							border-right-color: #888;
					}
			}

			&::before {
					content: '';
					width: 0px;
					height: 0px;
					border-top: 7px solid transparent;
					border-right: 9px solid #999;
					border-bottom: 7px solid transparent;
					left: -9px;
					position: absolute;
			}

			&::after {
					content: '';
					width: 3px;
					height: 3px;
					border-radius: 50%;
					position: absolute;
					background: $color-white;
					left: -3px;
					top: 5px;

			}
	}
}

.novosti-pagination {
	@extend .oglasi-pagination;
	border-top: 0;
}

.novost-heading {

	margin-bottom: 5px;

	.novost-main-heading {
			font-family: 'Tahoma';
			font-weight: bold;
			font-size: 38px;
			color: $color-primary;
			line-height: 110%;
			margin-bottom: 5px;
	}

	.heading-lead {
			font-size: 16px;
			color: #666;

			i {
					vertical-align: bottom;
					font-size: 17px;
			}

			.date {
					margin-right: 15px;
			}
	}

	.novosti-item-kategorije {
			display: inline-block;
			margin-left: 20px;
	}

	.novost-img {
			margin-top: 10px;
			position: relative;

			.novost-img-info {
					position: absolute;
					bottom: 0;
					right: 0;
					color: $color-white;
					background: rgba(0, 0, 0, .6);
					padding: 5px 13px;

					&::after {
							content: '';
							position: absolute;
							left: -3px;
							top: 0;
							width: 0;
							height: 0;
							border-bottom: 30px solid rgba(0, 0, 0, .6);
							border-left: 3px solid transparent;
					}
			}
	}
}

.novost-povezane-vesti-heading {
	font-weight: bold;
	font-size: 16px;
	text-transform: uppercase;
	background: #eee;
	display: inline-block;
	padding: 5px 8px;
	position: relative;
	border-top: 1px solid #ddd;
	border-left: 1px solid #ddd;
	border-bottom: 1px solid #eee;
	margin-bottom: -1px;

	&::before {
			content: '';
			height: 1px;
			width: 35px;
			background: #ddd;
			position: absolute;
			-webkit-transform: rotate(77deg);
			transform: rotate(77deg);
			right: -22px;
			bottom: 16px;
	}

	&::after {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			top: 0;
			right: -8px;
			border-bottom: 34px solid #eee;
			border-right: 8px solid transparent;
	}

}

.novost-povezane-vesti-content {
	background: #eee;
	padding: 7px 5px 5px 5px;
	border: 1px solid #ddd;
}

.novosti-povezane-vesti-item {
	float: left;
	width: 213px;
	height: 70px;
	margin-right: 6px;

	a {
			color: $color-text;

			@include hover-active-focus {
					color: $color-primary;
			}
	}
}

.novosti-povezane-vesti-item-img {
	float: left;
	margin-right: 5px;
}

.novosti-povezane-vesti-item-text {
	float: left;
	width: 110px;

	p {
			line-height: 125%;
	}
}

.podeli-vest {
	display: inline-block;
	vertical-align: middle;
	float: right;

	p,
	.podeli-vest-mreze {
			display: inline-block;
	}

	p {
			text-transform: uppercase;
			font-size: 16px;
			font-weight: bold;
			margin-right: 15px;
			vertical-align: top;
			line-height: 200%;
	}

	.kontakt-icon {

			&:last-of-type {
					margin-right: 5px;
			}
	}
}

.novosti-text {
	margin-top: 10px;
	font-size: 16px;
	text-align: justify;
	overflow: auto;

	p {
		margin-top: 15px;
		line-height: 130%;
	}

	.novost-text-img {

			p {
					width: 410px;
			}

			img {
					margin-top: 15px;
					margin-left: 15px;
			}
	}
}

.novost-izvor {
	float: left;
	font-size: 16px;
	margin-top: 15px;

	i {
			vertical-align: top;
			font-size: 18px;
			margin-right: 5px;
	}

	p {
			display: inline-block;
	}
}

.novost-tagovi {
	float: left;
	width: 100%;
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	font-size: 16px;
	padding: 10px;
	margin-top: 10px;

	p {
			display: inline-block;
			font-weight: bold;
			margin-right: 5px;
	}

	a {
			margin-right: 5px;
			color: $color-primary;
			font-weight: bold;

			@include hover-active-focus {
					color: $color-text;
			}
	}
}

.najcitanije-u-sedam-dana {
	float: left;
	width: 100%;
	margin-top: 5px;
	padding-bottom: 5px;
	border-bottom: 1px solid #ddd;
}

.najcitanije-u-sedam-dana-item {
	position: relative;
	width: 220px;
	float: left;
	margin-right: 5px;

	&:last-of-type {
			margin-right: 0;
	}

	a {
			color: $color-white;

			@include hover-active-focus {
					span {
							background: rgba(233, 28, 35, .6);
					}
			}

			span {
					background: rgba(0, 0, 0, .6);
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					padding: 5px;
					font-weight: bold;
					font-size: 16px;
			}
	}
}

.novost-komentari {
	@extend .oglasi-tab-content;
	padding: 0;
	margin-top: 5px;
}

.novost-komentari-header {
	float: left;
	width: 100%;
	margin-bottom: 5px;

	p {
			text-transform: uppercase;
			font-size: 16px;
			font-weight: bold;
			margin-top: 3px;
			margin-left: 5px;
			float: left;

			i {
					vertical-align: top;
					margin-right: 5px;
			}
	}

	select {
			float: right;
			font-family: 'Open Sans';
			font-size: 11px;
			border-radius: 0;
			width: 170px;
	}
}

.novost-komentari-postavi-pitanje {
	@extend .oglasi-tab-postavi-pitanje;

	.novost-komentari-msg {
			min-height: 120px;
	}
}

.novost-komentari-user {
	@extend .oglasi-pitanje-user;
}

.novost-komentari-user-name {
	@extend .oglasi-pitanje-user-name;
}

.novost-komentari-user-status {
	@extend .oglasi-pitanje-user-status;
}

.novost-komentari-msg {
	@extend .oglasi-pitanje-msg;
	width: 481px !important;

	button {
			margin-right: 7px;
	}
}

.novost-komentari-msg-header {
	@extend .oglasi-pitanje-msg-header;
}

.novost-komentari-item {
	@extend .oglasi-tab-pitanja-item;
	margin-top: 5px;
}

.novost-komentari-pitanje {
	@extend .oglasi-tab-pitanje;

	.novost-komentari-msg {
			width: 481px !important;
			min-height: 88px !important;
	}
}

.novost-komentari-odgovor {
	@extend .oglasi-tab-odgovor;

	.novost-komentari-msg {
			width: 451px !important;
			min-height: 88px !important;
	}
}

.novost-komentari-akcije {
	margin-top: 15px;
}

.novost-komentari-akcije-item {
	display: inline-block;
	font-family: 'Open Sans';
	font-size: 11px;
	text-transform: uppercase;
	margin-right: 8px;

	&:first-of-type {
			background: #999;
			padding: 1px 5px;
			border-radius: 3px;

			@include hover-active-focus {
					background: #101010;
			}

			a {
					color: $color-white;
			}

			i {
					color: $color-white;
			}
	}

	&:nth-of-type(2) {
			a {
					color: #4a8c4b;

					@include hover-active-focus {
							color: darken(#4a8c4b, 10%);
					}
			}
	}

	&:nth-of-type(3) {
			a {
					color: #c83a3f;

					@include hover-active-focus {
							color: darken(#c83a3f, 10%);
					}
			}

			i {
					vertical-align: middle;
			}
	}

	p {
			display: inline-block;
	}

	i {
			vertical-align: top;
	}

}

.odgovori-na-pitanje {
	@extend .novost-komentari-odgovor;
	float: right;

	textarea {
			width: 420px;
	}
}

.slanje-komentara-neregistrovan {
	float: left;
	width: 100%;
	border: 2px solid #e2e2e2;
}

.slanje-komentara-neregistrovan-header {
	background: #e2e2e2;
	text-transform: uppercase;
	text-align: center;
	font-family: 'Trebuchet MS';
	font-weight: bold;
}

.slanje-komentara-neregistrovan-content {
	float: left;
	width: 100%;
	padding: 10px;

	.neregistrovan-korisnik-form {
			width: 100%;

			.form-inputs {
					float: left;
					margin-right: 20px;
			}

			.form-textarea {
					float: left;

					textarea {
							width: 300px;
							height: 167px;
							margin-top: 18px;
							border: 1px solid #c0c0c0;
							padding: 5px;
							font-family: 'Open Sans';
							font-size: 12px;
							resize: none;
							border-radius: 1px;
					}
			}

			.form-footer {
					float: left;
					width: 100%;

					p {
							float: left;
							width: 410px;
							line-height: 115%;
					}
			}

			.sms-potvrda {
					.potvrda-msg {
							width: 315px;
							margin-right: 23px;
							margin-top: 0;
					}

					input,
					button {
							width: 145px !important;
							height: 45px !important;
					}
			}

			.neregistrovan-bez-potvrde {
					display: none;
			}

			.neregistrovan-form-submit-btn {
					float: right;
					margin-top: 2px;
					margin-right: 12px;
					font-family: 'Trebuchet MS';
					font-size: 14px;
					font-weight: bold;
					border: 0;
					background: $color-text;
					color: $color-white;
					padding: 5px;
					position: relative;
					cursor: pointer;

					@include hover-active-focus {
							background: $color-primary;

							&::before {
									border-bottom-color: $color-primary;
							}

							&::after {
									border-top-color: $color-primary;
							}
					}

					&::before {
							content: '';
							position: absolute;
							left: -4px;
							top: 0;
							width: 0;
							height: 0;
							border-bottom: 26px solid $color-text;
							border-left: 4px solid transparent;
					}

					&::after {
							content: '';
							position: absolute;
							width: 0;
							height: 0;
							top: 0;
							right: -4px;
							border-top: 26px solid $color-text;
							border-right: 4px solid transparent;
					}
			}
	}
}

.novost-registruj-se {
	@extend .oglasi-tab-registruj-se;
}