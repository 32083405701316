.form-group-file input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    opacity: 0;
}

.file-drop {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
}

.ad-image {
    width: 31%;
    float: left;
    margin: 1%;
    border-radius: 5px;
    margin-bottom: 7px;
    overflow: hidden;
    position: relative;
    z-index: 999;

    .img {
        width: 100%;
        height: 100%;
        position: relative;
    }
}

.img-container {
    width: 100%;
    height: 120px;
    position: relative;
    cursor: move;

    &:hover {
        .img-info {
            display: block;
        }
    }
}

.img > div {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    position: relative;
    overflow: hidden;

    &::after {
        content: '';
        background-image: inherit;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.img-info {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    display: none;
    color: white;
}

.info-size {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0px 0.4em;
    border-radius: 3px;
    margin-bottom: 1em;
    font-size: 16px;
    position: relative;
    top: 25px;
    cursor: default;
}

.filename {
    text-overflow: ellipsis;
    white-space: inherit;
    position: relative;
    top: 40px;
    overflow: hidden;
    width: 80%;
    left: 50%;
    transform: translateX(-50%);

    span {
        border-width: 1px;
        border-style: solid;
        border-color: transparent;
        border-image: initial;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 0px 0.4em;
        border-radius: 5px;
        white-space: nowrap;
        cursor: default;
    }
}

.img-actions {
    margin-top: 3px;
    display: flex;
    justify-content: space-between;
    font-size: 17px;

    span {
        cursor: pointer;
    }
}

.rotate90::after {
    transform: rotate(90deg);
    width: 120px !important;
    height: 200.25px !important;
    top: -40px !important;
    left: 40px !important;
}

.rotate180::after {
    transform: rotate(180deg);
}

.rotate270::after {
    transform: rotate(270deg);
    width: 120px !important;
    height: 200.25px !important;
    top: -40px !important;
    left: 40px !important;
}

.disable-hover {
    &:hover {
        .img {

            filter: none !important;
        }

        .img-info {
            display: none !important;
        }
    }
}

a {
    cursor: pointer;
}

.image-rotate {
    display: flex;
    position: relative;

    a {
        width: 48%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-right: 0;
        padding-left: 0;
        font-weight: 900;
    }
}

.input-addon-pre {
    background: #ccc;
    color: #101010;
    float: left;
    width: 40px;
    height: 34px;
    position: absolute;
    left: 0;
    bottom: 0;
    text-align: center;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    line-height: 32px;
    border: 0;
}

.logo-cropper {
    width: 200%;
    height: 30vh;
    position: relative;
    margin-bottom: 2rem;
}

.crop-container {
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    bottom: 80px;
    width: 100%;
}

.crop-button {
    position: absolute;
    bottom: -2rem;
    right: 0;

    background: #999;
	color: $color-white;
	text-transform: uppercase;
	padding: 6px 10px;
	border-radius: 3px;
	margin-top: 18px;
	font-family: 'Trebuchet MS';
	font-weight: bold;
	font-size: 16px;
    border: none;
    outline: none;
	cursor: pointer;

	@include hover-active-focus {
			background: $color-primary;
	}
}

select.input-addon-pre {
    padding: 0;
    padding-left: 5px;
}

.disabled-input {
    color: rgb(84, 84, 84) !important;
}

.pretraga-prodavnica-form input[type="text"].dd-select {
    width: 140px;
    display: inline-block;
    margin-right: 20px !important;
    color: #666;
    background: url(/img/dropdown.png) 96%/15% no-repeat #f9f9f9;
    background-size: 17px;
    border: 1px solid #c0c0c0;
    padding: 8px;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

.dd-select::placeholder {
    color: #666;
}

.select-search {
    height: 34px;
    display: inline-block;
    width: 140px;
    margin-right: 20px;
    top: 12.5px;

    input {
        width: inherit;
        height: inherit;
        color: #666;
        background: url(/img/dropdown.png) 96%/15% no-repeat #f9f9f9;
        background-size: 17px;
        padding: 8px;
        border: 1px solid #c0c0c0;
        padding-right: 30px;

        &::placeholder {
            color: #666;
        }
    }

    ul {
        background: #F9F9F9;
        border: 1px solid #c0c0c0;
        max-height: 200px;
        overflow-y: auto;
        color: #666;
        display: none;
        width: inherit;

        &.displayed {
            display: block;
        }

        li {
            display: block;
            width: 100%;
            padding: 2px 8px;

            &:hover {
                background: $color-primary;
                color: white;
                cursor: default;
            }

            &.selected {
                background: $color-primary;
                color: white;
                cursor: default;
            }
        }
    }
}

.notification-bar {
    z-index: 999999999999999999;
}

.error-field {
    border-color: red !important;
}

.oglas-image-thumb {
    background-size: cover;
    width: 160px;
    height: 120px;
    margin: 5px;
    cursor: pointer;
}

.oglasi-tab-postavi-pitanje.oglasi-tab-odgovor {
    float: right;

    .oglasi-pitanje-msg {
        width: 451px;
    }
}

.oglasi-tab-postavi-pitanje.oglasi-tab-odgovor .oglasi-pitanje-msg textarea {
    width: 415px;
}

.info-verifikacija-telefon {
    position: absolute;
    right: 0;
    top: 17px;
}

.promovisi-oglas-content {
    float: left;
    width: 100%;
    border: 1px solid #d9d9d9;
    padding: 20px;

    .heading {
        font-family: 'Tahoma';
        font-size: 18px;
        font-weight: bold;
    }

    .tipovi-oglasa {
        margin-top: 20px;
    }
}

.preloader {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.7);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    &-fixed {
        position: fixed;
    }
}

.preloader__icon {
    border: 10px solid #ffffff;
    border-top: 10px solid #101010;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    animation: spin 1500ms linear infinite;
    -webkit-animation: spin 1500ms linear infinite;
}

.ad-slot-wrapper {
    // @media (min-width: 320px) and (max-width: 480px) {
    //     display: none;
    // }
}

.lightbox-custom-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 3em;
    background-color: #000;
    text-align: center;
    padding-top: .5em;

    span {
        color: #FFF;
        font-weight: bold;
        font-size: 20px;
    }

    span:nth-child(1) {
        float: left;
        margin-left: 1em;
    }
}

.banner-left {
    width: 160px;
    height: 600px;
    position: fixed;
    left: 12px;
    top: 20%;

    @media only screen and (min-width : 1199px) {
        display: block;
    }
    
    @media only screen and (max-width : 1200px) {
        display: none;
    }
}

.banner-right {
    width: 160px;
    height: 600px;
    position: fixed;
    right: 12px;
    top: 20%;

    @media only screen and (min-width : 1199px) {
        display: block;
    }
    
    @media only screen and (max-width : 1200px) {
        display: none;
    }
}

.banner-300-768-left {
    width: 300px;
    height: 768px;
    position: fixed;
    margin-left: -300px;
    top: 0;

    @media (min-width: 320px) and (max-width: 999px) {
        display: none;
    }
}

.banner-300-768-right {
    width: 300px;
    height: 768px;
    position: fixed;
    margin-left: 1000px;
    top: 0;

    @media (min-width: 320px) and (max-width: 999px) {
        display: none;
    }
    
    @media (min-width: 1159px) {
        margin-left: 1200px;
    }
}

.banner-160-600-left {
    width: 160px;
    height: 600px;
    position: fixed;
    margin-left: -161px;
    top: 10px;

    @media (min-width: 320px) and (max-width: 999px) {
        display: none;
    }
}

.banner-160-600-right {
    width: 160px;
    height: 600px;
    position: fixed;
    margin-left: 1201px;
    top: 10px;

    @media (min-width: 320px) and (max-width: 999px) {
        display: none;
    }
}

.banner-120-600-left {
    width: 120px;
    height: 600px;
    position: fixed;
    margin-left: -121px;
    top: 10px;

    @media (min-width: 320px) and (max-width: 999px) {
        display: none;
    }
}

.banner-120-600-right {
    width: 120px;
    height: 600px;
    position: fixed;
    margin-left: 1201px;
    top: 10px;

    @media (min-width: 320px) and (max-width: 999px) {
        display: none;
    }
}

.banner-728-90 {
   width: 728px;
   height: 90px;
   display: inline-block;
}

.banner-300-250 {
    height: 250px;
    width: 300px;
}

.banner-690-100 {
    height: 100px;
    width: 690px;
    display: inline-block;
    margin-top: 5px;
}

.banner-1000-250 {
    width: 1000px;
    height: 250px;
}

.image-sides {
    position: absolute;
    width: 50%;
    height: 91%;
    top: 40px;
    display: flex;
    align-items: center;

    &.image-left, &.image-right {
        .arrow {
            width: 28px;
            height: 45px;
            position: absolute;

            &_icon {
                cursor: pointer;
                width: 100%;
                height: 100%;
                background-color: #ccc;
                opacity: .4;
                &:hover {
                    opacity: .9;
                }
            }
        }
    }

    &.image-right {
        left: 50%;
        .arrow-right {
            right: 5%;
            
            &_icon {
                clip-path: polygon(20% 5%, 90% 50%, 20% 95%, 20% 75%, 65% 50%, 20% 25%);
            }
        }
    }

    &.image-left {
        .arrow-left {
            left: 5%;
    
            &_icon {
                clip-path: polygon(80% 5%, 10% 50%, 80% 95%, 80% 75%, 35% 50%, 80% 25%);
            }
        }
    }
}


@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

.scroll-to-top-btn {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  transform: rotate(180deg);
  background-color: $color-primary;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  .fil0 { fill: $color-white; }

  &-elevated { bottom: 4.5rem; }
}
