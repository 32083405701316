.ad-breadcrumbs {
	padding: 8px 15px 8px 15px;

	&__ads_list {
		padding: 0px 15px 8px 15px;

		display: flex;
		flex-wrap: wrap;
		align-items: center;

		h1 {
			width: 100%;
			margin: 0;
		}

		h2 {
			font-family: 'Calibri', 'Open Sans', sans-serif;
			font-size: 1.25rem;
			font-weight: normal;
			text-transform: none;
			margin-top: 0;
			margin-bottom: 0;

			&::after { display: none; }
		}

		@include for-mobile {
			h2 {
				position: absolute;
				opacity: 0;
			}

			h1 {
				font-size: 20px;
				margin: 5px 0 0 0;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
	}

	&__icon {
		.fil0 { fill: $color-bg; }

		@include hover-active-focus {
			.fil0 { fill: $color-primary; }
		}
	}

	a {
		color: $color-text;
		font-size: 1rem;

		p {
			display: inline-block;
			font-size: 14px;
			text-transform: uppercase;
		}

		@include hover-active-focus {
			color: $color-primary;
		}
	}

	i {
		font-size: 20px;
		vertical-align: bottom;
		margin-right: 5px;
	}

	&__separator--pipe {
		&::after {
			content: "|";
			margin: 0 .5rem;
			font-size: 1rem;
			font-weight: bold;
		}
	}

	&__separator--slash {
		&::after {
			content: "/";
			margin: 0 .5rem;
			font-size: 12px;
			font-weight: bold;
		}
	}

	@include for-mobile-and-tablet {
		&__separator--slash, &__separator--pipe, &__link, &__icon {
			display: none;
			&::after {
				display: none;
			}
		}
	}
}