.login-form {
	position: relative;
	width: 58%;
	margin-top: 10px;

	.ico-top-meni-prijavi-se {
		width: 37px;
		height: 37px;
		margin-top: -2px;
		text-align: center;
		background: #fafafa;
		font-size: 18px;
		line-height: 33px;
		border: 1px solid #dcdae5;
	}

	input {
		width: 166px;
		height: 37px;
		padding: 0 5px;
		border: 1px solid #dcdae5;
		border-left: 0;

		font-family: 'Tahoma';
		font-weight: bold;
		font-size: 22px;
	}

	.sms-code {
		width: 60%;

		input, input[type="password"] {
			width: 100%;
			border-left: 1px solid #dcdae5;
			text-align: center;
		}
	}

	.sms-code-button {
		width: 60%;
	}

	button {
		background: $color-primary;
		border: 0;
		font-weight: bold;
		font-size: 16px;
		color: $color-white;
		padding: 10px;
		margin-top: 15px;
		cursor: pointer;
		border-radius: 3px;
		width: 232px;

		@include hover-active-focus {
			background: $color-text;
		}

		&:disabled {
			background: $color-text;
			cursor: not-allowed;
		}
	}

	.register-btn {
		display: block;
		text-transform: uppercase;
	}

	i {
		width: 37px;
		height: 37px;
		margin-top: -2px;
		text-align: center;
		background: #fafafa;
		font-size: 18px;
		line-height: 33px;
		border: 1px solid #dcdae5;
	}

	p {
		width: 60%;
	}

	.form-group {
		margin-bottom: 15px;

		&_error {
			input, select {
				border: 1px solid $color-secondary;
			}
		}

		&__login-phone {
			float: none;
			outline: none;
		}
	}

	.dial-label {
		width: 25%;
		border: 1px solid #dcdae5;
		text-align: center;
		position: relative;

		label {
			font-family: 'Tahoma';
			font-weight: bold;
			font-size: 22px;
			line-height: 0;

			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	input[type="password"] {
		width: 196px;
	}

	.select-wrap {
    width: 68px;
		height: 37px;
		
		cursor: pointer;

		label {
			margin-top: 7px;
			margin-right: 13px;
		}
	}

	.resend-sms-code {
		width: 60%;
		display: flex;
    flex-wrap: wrap;
		align-items: center;
		text-align: center;

		.countdown-text {
			width: 40%;
			font-size: 16px;
			font-weight: bold;
			color: $color-grey-dark;
		}

		button {
			width: 60%;
			font-size: 14px;
			margin-top: 0;
		}

		.support-text {
			margin-top: 1rem;
			font-size: 1.25rem;
			font-weight: bold;
			text-transform: uppercase;
			color: #e91c23;
		}
	}
}

.login-form-passwordless {
	width: 60%;

	.select-dropdown-picker {
		border: 1px solid #dcdae5;
		border-radius: 0;
	}
}

.info-polje {
	margin-bottom: 15px;
}
