/*
 * Footer styles
*/

footer {
	float: left;
	background: $color-text;
	color: $color-white;
	margin-top: 2px;
	width: 100%;

	p {
		margin: 0;

		@include for-mobile {
			font-size: 1rem;
		}
	}

	li {
		display: block;
		margin-bottom: 5px;

		@include for-mobile {
			font-size: 1rem;
		}
	}

	a {
		color: $color-white;

		@include hover-active-focus {
			text-decoration: underline;
		}
	}
}

.prodajete-tehniku {
	height: 88px;

	@include for-mobile {
		height: 4.5rem;
	}

	p {
		display: inline-block;
		float: left;
		width: 164px;

		@include for-mobile {
			float: none;
			width: 100%;
		}
	}

}


.dodaj-oglas-btn {
	background: $color-primary;
	padding: 10px 20px;
	border-radius: 4px;
	font-weight: bold;
	font-size: 16px;
	text-transform: uppercase;
	line-height: 22px;
	display: inline-block;
	float: left;
	width: 170px;

	@include hover-active-focus {
		text-decoration: none;
		background: $color-white;
		color: $color-primary;
	}

	&__fixed {
		@include hover-active-focus {
			text-decoration: none;
			background: $color-primary;
			color: $color-text;
		}
	}

	i {
		vertical-align: top;
		font-size: 22px;
		margin-right: 5px;
	}

	@include for-mobile-and-tablet {
		display: none;
	}

	&__mobile {
		@include for-mobile-and-tablet {
			display: block;
			float: left;
			margin: .25rem 0 0 1rem;
			width: 125px;
			padding: 10px;
			font-size: 14px;
		}

		i {
			@include for-mobile-and-tablet {
				display: none;
			}
		}
	}

	@include for-laptop-and-desktop {
		&__mobile {
			display: none;
		}
	}
}

.footer-main {
	padding: 10px 30px;

	@include for-mobile {
		padding: .25rem 1rem;
	}

	@include for-tablet {
		padding: 5px 15px;
	}

	>div,
	section:not(.footer-apps-mobile) {
		float: left;
		width: 150px;

		@include for-laptop-and-desktop {
			width: 155px;
		}

		@include for-tablet {
			width: 145px;
		}

		margin-right: 25px;

		@include for-tablet {
			margin-right: 15px;
		}

		&:last-of-type {
			width: 340px;
			margin-right: 0;
		}
	}

	.kategorije-oglasa,
	.navigacija {
		@include for-mobile-and-tablet {
			display: none;
		}
	}

	.vas-autodiler {
		@include for-mobile-and-tablet {
			float: none;
			width: 100%;
		}

		ul {
			@include for-mobile-and-tablet {
				display: flex;
				flex-wrap: wrap;
			}

			li {
				@include for-mobile-and-tablet {
					width: 45%;
				}
			}
		}
	}

	.footer-column-title {
		font-size: 14px;
		font-family: 'Trebuchet MS';
		font-weight: bold;
		text-transform: uppercase;
		display: inline-block;
		margin-block-start: 0.83em;
		margin-block-end: 0.83em;

		&::after {
			content: '';
			height: 3px;
			width: 100%;
			display: block;
			background: $color-primary;
			margin-top: 5px;
		}
	}
}

.footer-social {
	display: flex;
	flex-wrap: nowrap;

	@include for-mobile-and-tablet {
		flex-wrap: wrap;
	}

	a {
		.fil0 {
			fill: $color-white;
		}

		@include hover-active-focus {
			i {
				color: $color-primary;
			}

			.fil0 {
				fill: $color-primary;
			}
		}

		svg#svg-twitter {
			width: 41px !important;
			height: 41px !important;
			margin-right: 5px;
			background: #fff;
			border-radius: 21px;
			margin-top: 6px;

			@include hover-active-focus {
				background: $color-primary;
			}
		}
	}

	@include for-mobile-and-tablet {
		a {
			float: left;
		}
	}

	i {
		font-size: 40px;
		margin-right: 5px;
	}
}

.footer-prodaja {
	@include for-mobile-and-tablet {
		display: none;
	}

	ul {
		li {
			display: inline-block;

			&::after {
				content: '|';
				margin-left: 5px;
			}

			&:last-of-type {
				&::after {
					content: '';
				}
			}
		}
	}
}


.footer-bottom {
	background: #333333;
	width: 100%;
	text-align: center;
	position: relative;
	padding: 10px 0;

	@include for-mobile-and-tablet {
		padding: 10px;
	}
}

.web-dizajn {
	background: $color-text;
	position: absolute;
	right: 0;
	bottom: 0;
	font-size: 12px;
	padding: 5px 10px;

	@include for-mobile-and-tablet {
		display: none;
	}

	&::before {
		content: '';
		position: absolute;
		left: -4px;
		top: 0;
		width: 0;
		height: 0;
		border-bottom: 27px solid $color-text;
		border-left: 4px solid transparent;
	}

	a {
		color: $color-white;
	}
}

.footer-text {
	text-align: 'center';
	padding: .25rem 2rem;

	@include for-mobile-and-tablet {
		display: none;
	}
}

.footer-apps-mobile {
	@include for-laptop-and-desktop {
		display: none;
	}

	@include for-mobile-and-tablet {
		width: 100%;
		float: left;
	}

	.footer-apps-svg-mobile {
		@include for-tablet {
			width: 11rem;
			height: 100%;
			margin-right: .25rem;
		}

		&__app-store {
			@include for-tablet {
				width: 10rem;
			}
		}

		@include for-mobile {
			width: 8rem;
			height: 100%;
			margin-right: .2rem;
		}

		&__app-store {
			@include for-mobile {
				width: 7rem;
			}
		}
	}
}

.footer-apps {
	@include for-mobile-and-tablet {
		display: none;
	}

	.footer-apps-svg {
		width: 80%;
		height: 100%;
	}
}