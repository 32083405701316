.category-items {
	@include for-mobile-and-tablet {
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: 5px;
	}
}

.kategorije-item {
	@include for-mobile-and-tablet {
		width: 24.5%;
		height: 17.5vw;
	}

	i {
		@include for-mobile-and-tablet {
			font-size: 10vw;
		}
	}
}

.kategorije-item-text {
	p {
		@include for-mobile-and-tablet {
			white-space: nowrap;
			font-size: 3.25vw;
		}
	}
}

.kategorije {
	.filter-btn {
    width: 98.5%;
		color: $color-text;
    background: $color-grey-button;
    border-radius: 4px;
    height: 2rem;
		
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
		text-align: center;
    font-weight: bold;
    text-transform: uppercase;
		margin: 0.5rem 0.2rem;
	}
}

.kategorije-sub {
	@include for-mobile-and-tablet {
		padding: .25rem .75rem;
		flex-wrap: nowrap;
		overflow: auto;
	}
}

.kategorije-sub-item {
	@include for-mobile-and-tablet {
		margin: .75rem 0;
		margin-right: 10%;
	}

	&:last-of-type {
		@include for-mobile-and-tablet {
			margin-right: 0;
		}
	}

	// flex-grow: 1;
	// text-align: center;

	// &.active {
	// 	a {
	// 		color: $color-primary;
	// 	}
	// }

	// a {
	// 	color: $color-text;

	// 	@include hover-active-focus {
	// 		color: $color-primary;
	// 	}
	// }

	i {
		@include for-mobile-and-tablet {
			font-size: 7vw;
		}
	}
}
