.sms-ads {
	@include for-mobile {
		&__type {
			width: 100%;
			display: inline-block;
			margin-bottom: .5rem;
		}
	
		&__group {
			width: 100%;
			display: inline-block;
			margin-bottom: .5rem;
		}
	
		&__search {
			display: inline-block;
			width: 90%;
			height: 34px;
		}
	}
}

.sms-oglasi-item {
	@include for-mobile {
		height: auto;
	}
}

.sms-oglasi-item-content {
	@include for-mobile {
		width: 85%;
		float: left;
	}
}

.sms-oglasi-item-footer {
	@include for-mobile {
		position: static;
	}
}
