/*
 * Sidebar styles
*/

.sidebar {
  width: 300px;
  margin-top: 3px;

  @include for-desktop {
    width: 300px;
  }

  @include for-mobile-and-tablet {
    width: 100%;
  }
}

.izdvojeno-iz-ponude {
//   height: 460px;
  margin-top: 2px;
}

.sidebar-banner {
  margin-bottom: 2px;

  .sidebar-item {
    width: 274px;

    a {
      width: 100%;
    }
  }
}

.sidebar-header {
    background: $color-text;
    padding: 4px 7px 4px 20px;
    position: relative;

    @include for-mobile-and-tablet {
        margin-left: 2px;
        margin-right: 2px;
    }

    &::after {
        content: '';
        position: absolute;
        width: 9px;
        height: 0;
        top: 0;
        left: 0;
        border-top: 28px solid $color-primary;
        border-right: 4px solid transparent;
    }

    p {
        text-transform: uppercase;
        color: $color-white;
        display: inline-block;
    }
}

.sidebar-content {
    border: 1px solid #ccc;
    padding: 2px;
}

.sidebar-info {

    background: #f9f9f9;
    border: 1px solid #ccc;
    font-family: 'Open Sans';

    position: relative;
    padding: 3px 30px;

    p {
        color: #333;
        font-size: 11px;
        text-align: center;
    }

    i {
        position: absolute;
        right: 6px;
        top: 2px;
        font-size: 24px;
    }
}

.sidebar-items {
    height: 320px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.sidebar-item {
    border: 1px solid #ccc;
    margin-top: 2px;
    margin-bottom: 2px;
    width: 294px;
    height: 78px;
    font-size: 12px;
    clear: both;

    &>a {
        width: 274px;
        height: 78px;
    }
    
    @media (min-width: 320px) and (max-width: 480px) {
        width: 100%;
        &>a {
            width: 100%;
        }
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}

.sidebar-item-img {
    float: left;
    width: 103px;
    position: relative;

    &::after {
        content: '';
        background: url('/img/sprite.png') no-repeat;
        background-position: 0px -40px;
        width: 34px;
        height: 12px;
        position: absolute;
        top: 5px;
        left: 0;
    }
}

.sidebar-item-content {
    font-family: 'Open Sans', sans-serif;
    font-size: 11px;
    width: 189px;
    height: 76px;
    float: left;
    padding: 3px 4px;
    background: #f9f9f9;
    position: relative;

    li {

        &::after {
            content: '/';
            color: $color-primary;
            margin: 0 1px 0 3px;
        }

        &:last-of-type {
            &::after {
                content: '';
            }
        }
    }
}

.sidebar-item-heading {
    font-family: 'Trebuchet MS';
    margin-bottom: 2px;

    a {
        font-size: 14px;
        font-weight: bold;
        color: $color-text;
        position: relative;

        @include hover-active-focus {
            color: $color-primary;
        }
    }

    i {
        font-size: 16px;
        display: inline-block;
        vertical-align: bottom;
    }
}

.arrow-detaljnije {
    position: absolute;
    right: 20px;
    bottom: 5px;
    color: $color-text;

    @include hover-active-focus {
        color: $color-primary;
    }
}

.sidebar-footer-bg {
    background: #f9f9f9;
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
    margin-top: 2px;

    a {
        background: $color-text;
        color: $color-white;
        padding: 4px 10px;
        margin: 0 4px;
        position: relative;

        @include hover-active-focus {
            background: $color-primary;

            &::after {
                border-top-color: $color-primary;
            }

            &::before {
                border-bottom-color: $color-primary;
            }
        }

        &::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 0;
            right: -4px;
            border-top: 25px solid $color-text;
            border-right: 4px solid transparent;
        }

        &::before {
            content: '';
            position: absolute;
            left: -4px;
            top: 0;
            width: 0;
            height: 0;
            border-bottom: 25px solid $color-text;
            border-left: 4px solid transparent;
        }
    }
}

.preuzmite-aplikaciju {
    text-transform: uppercase;
    margin-bottom: 3px;

    .sidebar-content {
        padding: 0;
    }
}

.preuzmite-android,
.preuzmite-ios {
    float: left;
    width: 50%;
    text-align: center;
    font-size: 11px;
    font-family: 'Open Sans';
    height: 107px;

    @include hover-active-focus {
        i {
            background: $color-primary;
            color: $color-white;
        }

    }

    a {
        color: $color-text;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
    }

    i {
        font-size: 32px;
        background: #fff;
        border: 1px solid #b5b6b6;
        border-radius: 50%;
        width: 62px;
        height: 62px;
        line-height: 60px;
    }

    p {
        margin-bottom: 5px;
        margin-top: 12px;
    }
}

.preuzmite-android {
    background: #eee;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 0px;
        right: -10px;
        border-top: 109px solid #eee;
        border-right: 10px solid transparent;
    }
}

.preuzmite-ios {
    background: #ccc;
}

.star {
    font-size: 14px;
}

.sms-content {
  float: left;
}

.sms-items {
  height: 320px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.sms-item {
  position: relative;
  border: 1px solid #ccc;
  margin-top: 2px;
  margin-bottom: 2px;
  width: 100%;
  font-size: 12px;
  background: #f9f9f9;
  display: block;
  overflow: auto;
}

.sms-content-text {
  font-family: 'Open Sans';
  font-size: 11px;
  width: 219px;
  float: right;
  padding-right: 3px;

  @include for-mobile { width: 85vw; }
}

.sms-content-img {
  float: left;
  width: 50px;

  @include for-mobile { width: 10vw; }

  i {
    font-size: 20px;
    background: $color-white;
    width: 40px;
    height: 40px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 3px;
    padding-top: 4px;

    @include for-mobile {
      font-size: 5vw;
      width: 10vw;
      height: 10vw;
    }
  }
}

.sms-item-footer {
    display: inline-block;
    width: 100%;
    bottom: 1px;
    left: 0px;
}

.sms-item-footer-tel,
.sms-item-footer-date {
    display: inline-block;
    margin: 0px 3px;
}

.sms-item-footer-date {
    float: right;
}

.novosti-i-saveti {
    margin-top: 0px;
    margin-bottom: 2px;

    .sidebar-item {
        height: 117px;
        padding: 3px;
        background: #f9f9f9;

        &:first-of-type {
            margin-top: 0;
        }
    }
}

.novosti-i-saveti-heading {

    font-weight: bold;

    .date {
        float: left;
        width: 25px;
        height: 25px;
        position: relative;

        background: $color-text;
        color: $color-white;

        margin-left: 6px;
        margin-top: 3px;

        &::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 0;
            right: -4px;
            border-top: 25px solid #101010;
            border-right: 4px solid transparent;
        }

        &::before {
            content: '';
            position: absolute;
            left: -4px;
            top: 0;
            width: 0;
            height: 0;
            border-bottom: 25px solid #101010;
            border-left: 4px solid transparent;
        }

        p {
            line-height: 100%;
            text-align: center;
        }
    }

    .heading {
        width: 230px;
        float: left;
        padding: 3px 7px;
        font-family: 'Trebuchet MS';

        p {
            line-height: 110%;
        }

        a {
            color: $color-text;

            @include hover-active-focus {
                color: $color-primary;
            }
        }
    }
}

.novosti-sidebar-items {
    height: 355px;
}

.novosti-i-saveti-content {

    p {
        color: #666;
        text-align: justify;
        display: inline;
    }

    i {
        color: $color-primary;
        margin-top: -2px;
    }

    a {
        margin-left: 5px;

    }

}

.novosti-i-saveti-content-text {
    font-family: 'Open Sans';
    font-size: 11px;
    width: 153px;
    float: left;

    a {

        @include hover-active-focus {
            i {
                color: $color-text;
            }
        }
    }
}

.novosti-i-saveti-content-img {
    width: 100px;
    float: left;
    margin-left: 10px;
}

.kontakt {
    margin-top: 2px;

    .sidebar-content {
        background: #f9f9f9;
        padding: 8px 25px 8px 27px;
    }
}

.kontakt-icon {
    float: left;
    // width: 42px;
    position: relative;
    margin-right: 8px;

    a {
        vertical-align: middle;
    }

    i {
        color: #fff;
        height: 29px;
        width: 33px;
        text-align: center;
        line-height: 28px;
        font-size: 15px;
        vertical-align: top;
    }
}

.kontakt-facebook {
    i {
        background: $color-facebook;
    }

    &::before {
        content: '';
        position: absolute;
        left: -4px;
        top: 0;
        width: 0;
        height: 0;
        border-bottom: 29px solid $color-facebook;
        border-left: 4px solid transparent;
    }

    &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 0;
        right: -4px;
        border-top: 29px solid $color-facebook;
        border-right: 4px solid transparent;
    }

    @include hover-active-focus {
        i {
            background: darken($color-facebook, 10%);
        }

        &::before {
            border-bottom-color: darken($color-facebook, 10%);
        }

        &::after {
            border-top-color: darken($color-facebook, 10%);
        }
    }
}

.kontakt-twitter {
    i {
        background: $color-twitter;
    }

    &::before {
        content: '';
        position: absolute;
        left: -4px;
        top: 0;
        width: 0;
        height: 0;
        border-bottom: 29px solid $color-twitter;
        border-left: 4px solid transparent;
    }

    &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 0;
        right: -4px;
        border-top: 29px solid $color-twitter;
        border-right: 4px solid transparent;
    }

    @include hover-active-focus {
        i {
            background: darken($color-twitter, 10%);
        }

        &::before {
            border-bottom-color: darken($color-twitter, 10%);
        }

        &::after {
            border-top-color: darken($color-twitter, 10%);
        }
    }

}

.kontakt-google-plus {
    i {
        background: $color-google-plus;
    }

    &::before {
        content: '';
        position: absolute;
        left: -4px;
        top: 0;
        width: 0;
        height: 0;
        border-bottom: 29px solid $color-google-plus;
        border-left: 4px solid transparent;
    }

    &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 0;
        right: -4px;
        border-top: 29px solid $color-google-plus;
        border-right: 4px solid transparent;
    }

    @include hover-active-focus {
        i {
            background: darken($color-google-plus, 10%);
        }

        &::before {
            border-bottom-color: darken($color-google-plus, 10%);
        }

        &::after {
            border-top-color: darken($color-google-plus, 10%);
        }
    }
}

.kontakt-whatsapp {
    i {
        background: $color-whatsapp;
    }

    &::before {
        content: '';
        position: absolute;
        left: -4px;
        top: 0;
        width: 0;
        height: 0;
        border-bottom: 29px solid $color-whatsapp;
        border-left: 4px solid transparent;
    }

    &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 0;
        right: -4px;
        border-top: 29px solid $color-whatsapp;
        border-right: 4px solid transparent;
    }

    @include hover-active-focus {
        i {
            background: darken($color-whatsapp, 10%);
        }

        &::before {
            border-bottom-color: darken($color-whatsapp, 10%);
        }

        &::after {
            border-top-color: darken($color-whatsapp, 10%);
        }
    }

}

.kontakt-viber {
    i {
        background: $color-viber;
    }

    &::before {
        content: '';
        position: absolute;
        left: -4px;
        top: 0;
        width: 0;
        height: 0;
        border-bottom: 29px solid $color-viber;
        border-left: 4px solid transparent;
    }

    &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 0;
        right: -4px;
        border-top: 29px solid $color-viber;
        border-right: 4px solid transparent;
    }

    @include hover-active-focus {
        i {
            background: darken($color-viber, 10%);
        }

        &::before {
            border-bottom-color: darken($color-viber, 10%);
        }

        &::after {
            border-top-color: darken($color-facebook, 10%);
        }
    }
}

.kontakt-email {
    i {
        background: #e91c23;
    }

    &::before {
        content: '';
        position: absolute;
        left: -4px;
        top: 0;
        width: 0;
        height: 0;
        border-bottom: 29px solid #e91c23;
        border-left: 4px solid transparent;
    }

    &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 0;
        right: -4px;
        border-top: 29px solid #e91c23;
        border-right: 4px solid transparent;
    }

    @include hover-active-focus {
        i {
            background: darken(#e91c23, 10%);
        }

        &::before {
            border-bottom-color: darken(#e91c23, 10%);
        }

        &::after {
            border-top-color: darken(#e91c23, 10%);
        }
    }
}

.kontakt-telegram {
    &::before {
        content: '';
        position: absolute;
        left: -4px;
        top: 0;
        width: 0;
        height: 0;
        border-bottom: 29px solid #61A8DE;
        border-left: 4px solid transparent;
    }

    &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 0;
        right: -4px;
        border-top: 29px solid #61A8DE;
        border-right: 4px solid transparent;
    }

    @include hover-active-focus {
        img {
            background: darken(#61A8DE, 10%);
        }

        &::before {
            border-bottom-color: darken(#61A8DE, 10%);
        }

        &::after {
            border-top-color: darken(#61A8DE, 10%);
        }
    }
}

.sidebar-prodavac {
    margin-bottom: 2px;

    .sidebar-content {
        padding: 0px 8px 8px 8px;
    }
}

.sidebar-user {
  margin-top: .5rem;
  display: flex;
  flex-direction: column;

  .sidebar-user-main-info {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    
    @include for-laptop-and-desktop { justify-content: space-between; }
  }
}

.sidebar-user-free {
  .sidebar-user-img {
    @include for-laptop-and-desktop { width: 23.5%; }
    position: relative;
    
    i {
      font-size: 65px;
      margin-top: -8px;
    }
  }

  .sidebar-user-status {
    @include for-laptop-and-desktop { width: 75%; }
    @include for-mobile-and-tablet { margin-left: 1rem; }
    margin-top: 0;
  }
}

.sidebar-user-status {
    margin-top: 7px;

    .user-online-status {
        width: 11px;
        height: 11px;
        display: inline-block;
        border-radius: 50%;
        margin-right: 4px;
        vertical-align: middle;

        &.offline {
            background: #e91c23;
        }

        &.online {
            background: #139e00;
        }
    }
}

.sidebar-user-status-name {
    font-family: 'Trebuchet MS';
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    vertical-align: middle;
    width: 90%;
}

.sidebar-user-status-username {
    @extend .sidebar-user-status-name;
    margin-left: 14px;
}

.sidebar-user-verifikacija {
    display: flex;
    margin-top: 5px;
}

.sidebar-user-verifikacija-item {
    text-align: center;
    color: #bbb;
    border-right: 1px solid #d3d3d3;

    &:last-of-type {
        border-right: none;
    }

    &.is-verifikovana {
        color: $color-text;
    }

    p {
        font-family: 'Open Sans';
        font-size: 10px;
        line-height: 100%;
        margin-top: 2px;
        margin-left: 3px;
    }

    i {
        font-size: 17px;
    }
}

.sidebar-user-contact {
  width: 100%;
  background: #f9f9f9;
  border: 1px solid #d3d3d3;
  margin-top: 5px;

  @include for-tablet {
    display: flex;
    align-items: center;
  }
}

.sidebar-user-contact-content {
  &:first-of-type { border-bottom: 1px solid #d3d3d3; }

  i {
    float: left;
    font-size: 25px;
    margin-right: 8px;
  }

  padding: 5px 8px;

  @include for-tablet {
    width: 50%;
    display: inline-block;
    border-bottom: 0;
    padding: 1px 5px;

    &:first-of-type {
      border-bottom: 0;
      border-right: 1px solid #d3d3d3;
    }
  }
}

.sidebar-user-contact-lokacija {
  // height: auto;

  p {
    line-height: 120%;

    &:nth-of-type(2) {
      font-family: 'Trebuchet MS';
      font-weight: bold;
      text-transform: uppercase;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  @include for-tablet {
    // height: 100%;
  }
}

.sidebar-user-contact-telefon {
    font-family: 'Trebuchet MS';
    font-weight: bold;
    font-size: 16px;

    &::after {
      content: '';
      display: table;
      clear: both;
    }

    .skriven-broj-char {
        background: #dedede;
        margin-left: 4px;
        padding: 1px 2px;
        color: #999;
    }

    .dostupan-na,
    .prikazi-broj {
        font-family: 'Calibri';
        font-size: 14px;
        font-weight: normal;
        float: right;
        line-height: 240%;

        i {
            float: none;
            font-size: 20px;
            margin: -3px 0px 0px 2px;

            &.ico-ima-viber {
                color: $color-viber;
            }

            &.ico-ima-whatsapp {
                color: $color-whatsapp;
            }
        }
    }

    .prikazi-broj {

        a {
            color: $color-text;

            @include hover-active-focus {
                color: $color-primary;
            }
        }
    }

    p {
        line-height: 195%;
        float: left;
    }
}

.sidebar-user-akcije {
    display: flex;
    margin-top: 4px;
}

.sidebar-user-akcije-item {
    flex-grow: 1;
    text-align: center;
    background: #f9f9f9;
    border: 1px solid #ddd;
    margin-right: 1px;
    margin-bottom: 1px;
    

    @include hover-active-focus {
        background: #ffffeb;

        i {
            color: $color-primary;
        }
    }

    .active {
        background: #ffffeb;

        i {
            color: $color-primary;
        }
    }

    a {
        color: $color-text;
        padding: 3px;
        width: 100%;
        
    }

    p {
        font-family: 'Open Sans';
        font-size: 11px;
    }

    i {
        font-size: 24px;
    }
}

.sidebar-user-ostalo {
  width: 100%;
  border: 1px solid #ddd;
  margin-top: 4px;
  background: #f9f9f9;
}

.sidebar-user-ostalo-item {
  font-size: 16px;
  border-bottom: 1px solid #ddd;

  &:last-of-type {
    border-bottom: 0;
  }

  @include hover-active-focus {
    background: #ffffeb;

    i {
      color: $color-primary;
    }
  }

  span {
    display: inline-block;
  }

  a, span.sidebar__menu-span {
    white-space: nowrap;
    color: $color-text;
    width: 100%;
    height: 100%;
    padding: 6px 10px;
  }

  i {
    font-size: 25px;
    margin-right: 13px;
  }

  img {
    margin-right: 13px;
  }

  p {
    display: inline-block;
    vertical-align: middle;
  }

  @include for-tablet {
    width: 50%;
    display: inline-block;

    &:nth-of-type(1) {
      border-right: 1px solid #ddd;
    }
  }
}

.sidebar-user-profile-items {
  .sidebar-user-ostalo-item {
    i {
      font-size: 20px;
      width: 20px;
    }

    &.active {
      background: $color-primary;

      a, i, span {
        color: $color-white;
      }
    }

    @include hover-active-focus {
      background: $color-primary;

      a, i, span {
        color: $color-white;
      }
    }
  }
}

.novosti-sidebar {

    margin-bottom: 2px;
    float: left;
    border: 1px solid #ccc;

    .novosti-sidebar-header {
        float: left;
        width: 100%;
    }

    .novosti-sidebar-header-item {
        text-align: center;
        text-transform: uppercase;
        font-family: 'Open Sans';
        font-size: 11px;
        width: 99px;
        float: left;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        position: relative;

        @include hover-active-focus {
            background: $color-primary;

            a {
                color: $color-white;
            }

            &::after {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                top: 100%;
                left: 0;
                border-left: 50px solid transparent;
                border-right: 50px solid transparent;
                border-top: 7px solid $color-primary;
            }
        }

        &.active {
            background: $color-primary;
            a {
                color: $color-white;
            }

            &::after {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                top: 100%;
                left: 0;
                border-left: 50px solid transparent;
                border-right: 50px solid transparent;
                border-top: 7px solid $color-primary;
            }
        }

        &:last-of-type {
            width: 100px;
            border-right: 0;
        }

        a {
            color: #999;
            display: block;
            padding: 5px;
            i {
                font-size: 18px;
            }
        }

    }

    .sidebar-footer-bg {
        float: left;
        width: 100%;
    }

    .sidebar-items {
        overflow-y: hidden;
        height: auto;
    }

    .sidebar-content {
        padding: 12px 2px 2px 2px;
        border: 0;
        float: left;
    }

    .sidebar-item {
        border: 0;
        width: auto;
        height: auto;
        padding: 0px 7px 0px 10px;
        float: left;

        &::after {
            content: '';
            width: 277px;
            height: 1px;
            display: block;
            margin: 5px auto;
            float: left;
            background: $color-text;
        }

        &:nth-of-type(3) {

            &::after {
                display: none;
            }
        }
    }
}

.novosti-sidebar-heading {
    font-family: 'Trebuchet MS';
    font-weight: bold;
    font-size: 14px;

    .heading {
        float: left;
    }

    a {
        color: $color-text;

        p {
            line-height: 120%;
        }

        @include hover-active-focus {
            color: $color-primary;
        }
    }
}

.novosti-sidebar-content-text {
    font-family: 'Open Sans';
    font-size: 11px;
    width: 167px;
    float: left;

    p {
        display: inline;
    }

    a {
        margin-left: 5px;
        color: $color-primary;

        i {
            vertical-align: top;
        }

        @include hover-active-focus {
            color: $color-text;
        }
    }
}

.novosti-sidebar-content-img {
    width: 100px;
    float: left;
    margin-left: 10px;
}

.sidebar-user-prodavnica {
  .sidebar-user-img {
    width: 36.5%;
    position: relative;
    border: 1px solid #ddd;

    &.pro::after {
      content: '';
      background: url(/img/sprite.png) no-repeat;
      background-position: 0px -40px;
      width: 34px;
      height: 12px;
      position: absolute;
      top: 5px;
      left: 0;
    }
  }

    .sidebar-user-status {
        width: 60%;
        margin-left: 11px;
        margin-top: 0;
    }

    .sidebar-user-verifikacija-item {
        border-right: 0;
        width: 28px;
        text-align: left;
    }

    ul {
        display: block;
        width: 100%;
        float: left;
        margin-top: 10px;

        li {

            &:last-of-type {
                &::after {
                    display: none;
                }
            }

            &::after {
                content: '/';
                color: $color-primary;
                margin-left: 5px;
            }
        }
    }

    .sidebar-user-radno-vreme {
        font-family: 'Trebuchet MS';
        font-weight: bold;

        p {
            line-height: 34px;
        }

        span {
            text-transform: uppercase;
        }
    }

    .sidebar-user-radno-vreme-item {
        width: 93px;
        float: left;
        text-align: center;
        text-transform: uppercase;
        padding: 5px;
        border-right: 1px solid #d3d3d3;

        &:last-of-type {
            border-right: 0;
        }

        p {
            line-height: 110%;
        }
    }

    .sidebar-user-contact-lokacija {
        position: relative;

        a {
            display: block;
            color: $color-text;
        }

        .sidebar-user-contact-lokacija {

            &:nth-of-type(odd) {
                background: #e9e9e9;
            }
        }

        .vise-lokacija {
            position: absolute;
            right: 0;
            top: 4px;
            color: $color-text;

            p {
                position: absolute;
                color: white;
                text-align: center;
                top: 5px;
                left: 5px;
            }
        }

        @include hover-active-focus {

            .vise-lokacija {
                color: $color-primary;
            }

            .sidebar-vise-lokacija-dropdown {
                display: block;
            }
        }
    }

    .sidebar-user-contact-lokacija-img {
        height: auto;
        padding: 0;
    }

    .sidebar-vise-lokacija-dropdown {
        position: absolute;
        width: 280px;
        top: 100%;
        left: 0;
        display: none;
        border-top: 1px solid #d3d3d3;
        border-bottom: 1px solid #d3d3d3;

        .sidebar-user-contact-content {
            background: #f9f9f9;
            padding: 5px 8px;

            @include hover-active-focus {
                background: #d2d2d2;
            }
        }
    }
}

/**
 *
 * Korisne informacije
 *
 */

.sidebar-korisne-informacije {
    margin-bottom: 2px;

}

.korisne-informacije-item {
    background: #f9f9f9;
    border: 1px solid #d9d9d9;
    margin-bottom: 2px;

    a {
        width: 100%;
        height: 100%;
        padding: 5px 0px 5px 10px;

        color: $color-text;

        @include hover-active-focus {
            background: #fffff0;

            i {
                color: $color-primary;
            }
        }
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    &:nth-of-type(4),
    &:nth-of-type(5),
    &:nth-of-type(6) {
        i {
            font-size: 40px;
            margin-top: 10px;
            margin-left: -3px;
        }
    }

    &:nth-of-type(2),
    &:nth-of-type(7) {
        i {
            margin-left: -4px;
        }
    }
}

.korisne-informacije-img {
    float: left;
    width: 60px;

    i {
        font-size: 55px;
    }
}

.korisne-informacije-content {
    float: left;
    width: 210px;

    p {
        line-height: 125%;

        strong {
            font-family: 'Trebuchet MS';
        }
    }
}
