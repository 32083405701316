.cookie-consent {
	background-color: rgba(0, 0, 0, .8);
	color: $color-white;
	width: 100%;
	padding: 4px;
	position: fixed;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	button {
			background-color: $color-primary;
			color: $color-white;
			text-transform: uppercase;
			margin-left: 10px;
			border: none;
			border-radius: 2px;
			padding: 8px 6px;
			cursor: pointer;
	}

	a {
			color: $color-white;
			text-decoration: underline;
	}
}