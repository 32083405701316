
.old-browser {
	width: 100%;
	background-color: $color-white;
	padding: 100px 0;

	img {
			display: block;
			margin: auto;
	}

	&__message {
			width: 370px;
			margin: 25px auto;
	}

	&__container {
			text-align: center;
	}

	&__item {
			padding: 50px;
	}

	&__logo {
			width: 40px;
			height: 40px;
	}

	&__availableLogo {
			width: 32px;
			height: 35px;
	}
}