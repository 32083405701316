.category-horizontal-menu-mobile {
	@include for-laptop-and-desktop {
		display: none;
	}

	margin-top: 3px;

	.filter-btn {
		width: 98.5%;
		color: $color-text;
		background: $color-grey-button;
		border-radius: 4px;
		height: 2rem;

		display: flex;
		justify-content: center;
		align-items: center;

		font-size: 14px;
		text-align: center;
		font-weight: bold;
		text-transform: uppercase;
		margin: 0.5rem 0.2rem;
	}

	.sausage-buttons {
		display: flex;
		margin: .5rem .2rem;
		overflow: auto;
		padding-bottom: .5rem;

		@include for-mobile-and-tablet {
			padding-bottom: 0;
		}
	}

	.sausage-button {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #fafafa;
		padding: 0.2rem 0.125rem 0.2rem 0.6rem;
		border-radius: 1rem;
		margin-right: .5rem;
		border: 1px solid #dddddd;

		&-close {
			margin-left: 5px;
			margin-right: 2px;
		}

		p {
			white-space: nowrap;
			line-height: normal;
		}
	}

	&-items {
		display: flex;
		overflow: auto;
		align-items: center;
		padding-bottom: .5rem;
	}

	&-item {
		display: flex;
		flex-direction: column;
		margin-right: 1px;
		justify-content: center;
		align-items: center;
		margin-bottom: auto;
		text-decoration: none;
		color: $color-text;

		p {
			width: 5.25rem;
			font-size: 12px;
			text-align: center;
			margin-top: auto;
			margin-bottom: 1rem;
		}

		@include for-mobile-and-tablet {
			p {
				margin-bottom: 0;
			}
		}

		&.active {
			.svg-container {
				.fil0, .fil1 {
					fill: $color-white;
				}

				border: 2px solid $color-primary;

				span {
					background-color: $color-primary;
				}
			}
		}
	}

	.svg-container {
		background-color: $color-white;
		width: 4.25rem;
		border-radius: 2.125rem;
		height: 4.25rem;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 5px;
		border: 2px solid #eeeeee;

		span {
			background-color: #eeeeee;
			width: 3.5rem;
			height: 3.5rem;
			border-radius: 1.75rem;
			position: relative;
		}
	}

	.select-dropdown-picker {
		width: 98%;
		margin-left: auto;
		margin-right: auto;
		display: block;
	}

	.select-dropdown-selected {
		display: block;
	}
}