
.bank-transfer-result {
	@extend .discount-card-verification;

	margin-left: 0;
	margin: 1rem auto;
}

.bank-transfer-text {
	ul {
		padding: 3px;
		li {
			display: block;
		}
	}
}