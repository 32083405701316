
.kategorije-pretraga {
	padding: 4px;
	position: relative;
	width: 100%;
	display: flex;
	flex-wrap: wrap;

	&.kategorije-pretraga-small {
			padding-bottom: 20px;
	}
}

.kategorije-pretrage {
	padding: 15px 15px 0px 15px;
}

.kategorije-pretraga-mega-menu {
	width: 651px;
	height: 410px;
	position: absolute;
	top: 37px;
	left: 0;
	z-index: 11;
	border: 1px solid #c0c0c0;
	border-top: 0;
	background: $color-white;
	visibility: hidden;
}

.kategorije-pretraga-mega-menu-content::-webkit-scrollbar {
	pointer-events: none;
}

.kategorije-pretraga-mega-menu-content::-webkit-scrollbar {
	width: 1em;
}

.kategorije-pretraga-mega-menu-content::-webkit-scrollbar-track {
	background: #f5f5f5;
}

.kategorije-pretraga-mega-menu-content::-webkit-scrollbar-thumb {
	background-color: #aaa;
	border: 2px solid #f5f5f5;

	@include hover-active-focus {
			background-color: darken(#aaa, 10%);
	}
}

.kategorije-pretraga-mega-menu-list {
	width: 175px;
	float: left;
	max-height: 100%;
	overflow: auto;

	li {
			display: block;

			@include hover-active-focus {
					a {
							background: $color-primary;
							color: $color-white;
					}
			}

			a {
					background: $color-white;
					font-weight: bold;
					color: $color-text;
					text-transform: uppercase;
					font-size: 13px;
					padding: 5px 15px;
					height: auto;
					width: 100%;
			}
	}

	li.active {
			a {
					background: $color-primary;
					color: $color-white;
			}
	}
}

.kategorije-pretraga-mega-menu-content {
	width: 474px;
	float: left;
	overflow-y: scroll;
	height: 100%;
	text-align: center;
	border-left: 1px solid #c0c0c0;
	padding-bottom: 4px;
}

.kategorije-pretraga-mega-menu-content-header {
	font-size: 14px;
	text-transform: uppercase;
	width: 100%;
	// float: left;
	padding: 2px 2px 0px 2px;

	p {
			position: relative;

			&::before {
					content: '';
					background: url('/img/line-pattern.png');
					width: 120px;
					height: 17px;
					position: absolute;
					left: 1px;
					top: 2px;
			}

			&::after {
					content: '';
					background: url('/img/line-pattern.png');
					width: 120px;
					height: 17px;
					position: absolute;
					right: 2px;
					top: 2px;
			}
	}

	&:nth-of-type(23) {
			p {

					&::before,
					&::after {
							width: 110px;
					}
			}
	}

}

.kategorije-pretraga-mega-menu-content-item {
	width: 100%;
	display: flex;
	flex-wrap: wrap;

	.pretraga-item {
		width: 33%;
		padding: 6px;
		margin-right: 2px;
		background: #f7f7f7;
		border: 1px solid #ddd;
	
		@include hover-active-focus {
			background: #ddd;
			border: 1px solid #c0c0c0;

			a {
				background: #ddd;
			}
		}
	
		a {
			width: 100%;
			display: block;
			height: 100%;
			background: #f7f7f7;
			padding: 6px 0px 6px 0px;

			i {
				color: $color-text;
				font-size: 27px;
				position: static;
			}

			img {
				width: auto;
				max-height: 30px;
				margin-bottom: 5px;
			}
		}
	
		p {
			color: $color-text;
			line-height: 1.1;
		}
	
		&:nth-of-type(3n + 3) {
			@include for-laptop-and-desktop { margin-right: 0; }
			@include for-tablet { margin-right: 0; }
		}

		@include for-mobile {
			width: 49%;
		}
	}
}

