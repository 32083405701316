.moji-oglasi-item {
	@include for-mobile-and-tablet {
		display: flex;
		flex-wrap: wrap;
	}
}

.garaza-item-slika {
	@include for-mobile-and-tablet {
		height: 100%;
    background-size: contain;
	}
}


	
.garaza-akcije {
	@include for-mobile-and-tablet {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}

	.garaza-akcije-item {
		@include for-mobile-and-tablet {
			flex-grow: initial;
			width: 100%;
			margin-bottom: 2px;
		}

		a {
			@include for-mobile-and-tablet {
				font-size: 1rem;
				padding: 0.5rem 0;
			}
		}
	}
}

.form-group-star-specification {
	label {
		@include for-mobile-and-tablet { margin-right: 5%; }
	}
}
