

.sacuvani-oglasi-filter {
	i {
			font-size: 18px;
	}

	.tooltip {
			top: 36px;
			left: -37px;
	}

	.oglasi-filter-content {
			li {
					&.active {
							a, i {
									color: $color-text;
							}

					}
			}

	}

	.oglasi-filter-grid {
			width: 71px;

			li {
					&.active {
							a, i {
									color: $color-text;
							}

					}
			}

			li:last-of-type {

					&.active {
							a {
									i {
											color: #777;
									}
							}
					}

					a {

							@include hover-active-focus {
									i {
											color: $color-text;
									}
							}

							i {
									color: #d9d9d9;

							}
					}
			}

	}

}

.sacuvani-oglasi-content {
	width: 100%;
	float: left;
	min-height: 1000px;
}