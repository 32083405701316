.secure-shopping-box {
	position: relative;
	border: 1px solid $color-secondary;
	margin: 4px;
	padding: 4px;
  font-size: 19px;

	span {
		display: block;
    float: right;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
    background: $color-secondary;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    line-height: 0;
	}

	p {
		margin-top: 1.5rem;
		float: left;
	}
}