.profile-ads-menu {
	&-item {
		@include for-mobile-and-tablet {
			padding: 0;
		}
	}

	&.poruke-pretraga {
		.input-pretraga {
			@include for-mobile-and-tablet {
				display: none;
			}
		}
	}
}