.lang-list {
	height: 30px;
	background: white;
	color: black;
	padding-left: 5px;
	font-size: 14px;
	border: none;
	margin-left: 10px;
	display: flex;
	flex-direction: column;

	@include for-mobile-and-tablet {
		max-height: 250px;
		position: absolute;
		top: 1.25rem;
		right: 5.75rem;
		background-color: transparent;
	}

	z-index: 1000;

	li {
		display: flex;
		align-items: center;
		cursor: pointer;

		z-index: 1000;

		@include for-mobile-and-tablet {
			margin-top: 5px;

			&:first-child {
				margin-top: 0;
			}
		}

		&:not(.active) {
			display: none;
		}

		background-color: $color-white;

		a {
			&::before {
				display: none;
			}

			&::after {
				display: none;
			}

			line-height: 0;
		}

		&.active {
			background-color: transparent !important;
			color: $color-text;
		}

		&:hover {
			p {
				color: $color-white;
			}
		}

		img {
			width: 25px;
			height: 17px;
			border: 1px solid $color-text !important;

			@include for-mobile-and-tablet {
				width: 45px;
				height: 30px;
			}
		}

		p {
			@include for-mobile-and-tablet {
				display: none;
			}
		}
	}

	&:hover {
		li {
			&:not(.active) {
				display: flex;
			}

			&.active {
				p {
					display: inline;
					color: $color-text;

					@include for-mobile-and-tablet {
						display: none;
					}
				}
			}
		}
	}

	&_item-text {
		padding-right: .5rem;
	}
}