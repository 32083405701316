.ios-modal {
	padding: 1rem 1.5rem 2rem 1.5rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	p {
		font-size: 1em;
		text-align: center;
	}

	a {
		background-color: $color-primary;
		color: $color-white;
		text-decoration: none;
		margin-top: 1rem;
		border: none;
		outline: none;
		border-radius: 2px;
		padding: 1rem 2rem;
		cursor: pointer;
	}
}