.sacuvani-oglasi-content {
	@include for-mobile-and-tablet {
		min-height: auto;
	}
}

.sacuvani-oglasi-filter {
	.oglasi-filter-content {
		@include for-mobile {
			width: 100%;
		}

		ul {
			@include for-mobile {
				display: flex;
			}
	
			li {
				@include for-mobile {
					flex-grow: 1;
				}
			}
		}
	}
}
