.online-payment {
	&__fields {

		.form-group {
			@include for-mobile {
				width: 100%;
				margin-right: 0;
				margin-bottom: .5rem;
			}

			.form-group__mask {
				@include for-mobile {
					width: 15%;
				}
			}
		}
	}

	.moji-oglasi-content {
		@include for-mobile-and-tablet {
			min-height: auto;
			border-top: 1px solid #c0c0c0;
		}
	}

	.moji-oglasi-item {
		@include for-mobile {
			flex-direction: column;
		}

		&-img {
			@include for-mobile {
				width: 100%;
			}
		}

		.oglas-item-slika {
			
			@include for-mobile {
				min-height: 235px !important;
			}
		}
	}

	.oglas-item {
		&__right {
			@include for-mobile {
				max-width: 100%;
			}
		}

		&__left {
			@include for-mobile {
				width: 100%;
			}
		}
	}
}