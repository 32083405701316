.profile-ads-menu {
	display: flex;
	width: 100%;
	border: 1px solid #c0c0c0;
	align-items: center;
	// justify-content: space-between;


	&-item {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-grow: 1;
		padding-top: .6rem;
		padding-bottom: .6rem;
		height: 2.2rem;
		cursor: pointer;

		a {
			color: $color-text;
		}

		@include hover-active-focus {
			background-color: $color-grey-light;
		}

		&.active {
			background-color: $color-primary;
			border-bottom: 3px solid $color-secondary;
			border-top: 3px solid transparent;

			a {
				color: $color-white;
			}
		}
	}

	&.poruke-pretraga {
		text-wrap: nowrap;
		padding: 0;
		background: $color-white;

		.input-pretraga {
			margin-left: 2px;
	
			input {
				height: 2rem;
				width: 100%;
			}

			flex-grow: 3;

			button {
				max-height: 32px;
				margin-left: 0;

				position: absolute;
				right: 0;
				padding: 0;

				i {
					height: 32px;
					margin-bottom: 2px;
				}
			}

			form {
				position: relative;
				width: 100%;
			}
		}
	}
}