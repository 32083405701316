.kategorije-pretraga {
	@include for-mobile-and-tablet {
		padding: .5rem 1rem 0 1rem;
	}

	.polja-pretrage-item {
		@include for-mobile {
			width: 100%;
			margin-right: 0;
		}

		@include for-tablet {
			width: 46.5%;

			&:nth-of-type(2n + 2) {
				margin-right: 3px;
			}
		}

		&.empty {
			@include for-mobile { display: none; }
		}
	}

	.ads-filter__row {
		@include for-tablet {
			width: 50%;
		}

		@include for-mobile {
			width: 100%;
		}

		.polja-pretrage-item {
			@include for-mobile {
				width: 100%;
			}
		}
	}
}

.filter-nav-mobile {
	width: 100%;
	display: flex;

	&-item {
		width: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		border-bottom: 1px solid #D3D3D3;
		padding: .2rem;

		&:nth-of-type(2) {
			border-left: 1px solid #D3D3D3;
		}

		p {
			color: $color-text;
			font-size: 1rem;
			margin-left: .5rem;
		}

		&.active {
			background-color: #d3d3d3;
		}
	}
}
