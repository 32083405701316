/*
 * INDEX STYLE
*/
@import "../abstracts/variables";

main {
    display: flex;

    &.forApp {
        .main-content {
            width: 100%;
            margin: 0;
        }
    }

    @include for-mobile-and-tablet {
        flex-wrap: wrap;
    }
}

.main-content {
    width: 692px;
    margin: 3px 2px 1px 3px;
    position: relative;

    @include for-laptop-and-desktop {
        width: 74.5%;
    }

    @include for-mobile-and-tablet {
        float: none;
        width: 100%;
        margin: 0;
    }
}

.sve-kategorije-btn {
  width: 26%;
  float: left;
  position: relative;

  i {
    position: absolute;
    right: 12px;
    top: 15px;
  }

  @include hover-active-focus {
    .kategorije-pretraga-mega-menu {
      visibility: visible;
    }
  }

  @include for-mobile-and-tablet {
    width: 50%;
  }
}

.search-dropdown {
    width: 439px;
    display: inline-block;
    position: absolute;
    background-color: #f9f9f9;
    border: 1px solid #c0c0c0;
    left: 176px;
    top: 37px;
    z-index: 100;

    p {
        margin: 2px 5px;
        opacity: .6;
        cursor: pointer;
        &:hover {
          opacity: 1;
        }
    }

    &__profile {
        left: 355px;
        top: 67px;
    }
}

.pretraga-dropdown {
    width: 100%;
    float: left;
    position: relative;
    font-size: 16px;
    margin-bottom: 5px;

    .categories-dropdown__button {
        width: 100%;
        float: left;
        background: $color-text;
        height: 37px;
        color: $color-white;
        padding: 9px 15px;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;

        .ads-header-ico {
            font-size: 7px;
        }
    }

    input {
        width: 68%;
        float: left;
        height: 37px;
        background: #f9f9f9;
        padding: 10px;
        border: 1px solid #c0c0c0;
        border-left: 0;
        outline: none;
    }

    .ads-search__button {
        float: left;
        padding: 3px 6px 4px 7px;
        height: 37px;
        margin-left: -2px;
        cursor: pointer;
        background: $color-primary;
        border: 0;
        color: $color-white;
        font-size: 24px;
    }
}

/*
 * SMS OGLASI STYLE
*/

.sms-oglasi-header {
    @extend .page-header;
}

.pretraga-sms-oglasa {
    float: left;
    width: 100%;
    border: 1px solid $color-text;
    border-top: 0;
    padding: 15px 18px;

    p {
        line-height: 120%;
        color: #333;
    }
}

.pretraga-sms-oglasa-header {
    font-family: 'Trebuchet MS';
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 5px;

    .heading {
        float: left;
    }

    .broj-oglasa {
        float: right;

        span {
            color: $color-primary;
        }
    }

}

.pretraga-sms-oglasa-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;

    margin-top: 5px;

    select {
        width: 100%;
        display: inline-block;
        margin-right: 20px;
        border-radius: 0;
        color: #666;
        height: 34px;
    }

    input[type="text"] {
        display: inline-block;
        width: 100%;
        height: 34px;
        background: #f9f9f9;
        padding: 10px;
        border: 1px solid #c0c0c0;
        outline: none;
    }

    button {
        position: relative;
        padding: 3px 6px 4px 7px;
        height: 34px;
        margin-left: -6%;
        cursor: pointer;
        background: #e91c23;
        border: 0;
        color: #fff;
        font-size: 24px;

        i {
            vertical-align: top;
        }
    }

    ::-webkit-input-placeholder {
        color: #ccc;
        font-family: 'Calibri';
        font-size: 14px;
    }

    :-moz-placeholder {
        color: #ccc;
        font-family: 'Calibri';
        font-size: 14px;
    }

    ::-moz-placeholder {
        color: #ccc;
        font-family: 'Calibri';
        font-size: 14px;
    }

    :-ms-input-placeholder {
        color: #ccc;
        font-family: 'Calibri';
        font-size: 14px;
    }
}

.sms-oglasi-content {
    @extend .registracija-content;
    flex-direction: column;
    padding: 3px 1px;
    min-height: 125px;
}

.sms-novosti-pagination {
    @extend .novosti-pagination;
}

.sms-oglasi-item {
    width: 100%;
    height: 78px;
    padding: 3px;
    margin-bottom: 2px;
    margin-left: 2px;
    margin-right: 2px;
    background: #f9f9f9;
    border: 1px solid #d9d9d9;

    &.premium-sms-oglas-item {
        background: #ffffeb;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}

.sms-oglasi-item-img {
    float: left;
    width: 45px;

    i {
        width: 40px;
        height: 40px;
        background: $color-white;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        font-size: 27px;
        text-align: center;
    }
}

.sms-oglasi-item-content {
    float: left;
    width: 90%;
    font-family: 'Open Sans';
    font-size: 12px;
    position: relative;
    height: 100%;
}

.sms-oglasi-item-footer {
    width: 100%;
    margin-top: 5px;
    position: absolute;
    bottom: 0;
    left: 0;

    .sms-oglasi-mobilni {
        float: left;
    }

    .sms-oglasi-date {
        float: right;
        margin-right: 5px;
    }
}

.prodavnice-header {
    @extend .sms-oglasi-header;
}

.pretraga-prodavnica {
    @extend .pretraga-sms-oglasa;
}

.pretraga-prodavnica-header {
    @extend .pretraga-sms-oglasa-header;

}

.broj-prodavnica {
    @extend .broj-oglasa;
}

.pretraga-prodavnica-form {
    @extend .pretraga-sms-oglasa-form;

    .users-list {
        &__filter-row {
            width: 100%;

            display: flex;
            flex-wrap: wrap;
            flex-direction: row;

            margin-bottom: 3px;

            button {
                margin-left: -4%;
            }
        }

        &__select {
            width: 33%;
            margin-right: 3px;
            display: inline-block;

            select {
                width: 100%;

                &:disabled {
                    color: #a395a4;
                }
            }

            optgroup {
                font-family: 'Calibri';
                color: #a395a4;
                font-weight: 300;
            }

            option {
                font-family: 'Calibri';
                color: #666;
            }

            &_last {
                margin-right: 0;
            }
        }
    }

    .users-list__search {
        display: inline-block;
        width: 62.5%;

        input[type="text"] {
            width: 100%;
            line-height: 3;
        }

        &_short {
            width: 28%;
        }
    }

    .users {
        &__filter-row {
            width: 96.5%;
        }
    }
}

.prodavnice-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    min-height: 125px;
    justify-content: space-between;
}

.user-item {
    width: 33%;
    border: 1px solid rgb(97, 96, 96);
    background: #fff;
    margin-top: 3px;
    font-family: 'Trebuchet MS';
    position: relative;

    .user-header {
        width: 100%;
        background: #f9f9f9;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        border-bottom: 1px solid #666;

        a {
            color: $color-text;

            @include hover-active-focus {
                color: $color-primary;
            }
        }
    }

    .user-content {
        padding: 2px;
        display: flex;
        flex-direction: row;
    }

    .user-img {
        width: 104px;
        position: relative;
        
		@media (min-width: 1199px) {
            width: 136px;
		}

        a {
            width: 103px;
            height: 77px;
            overflow: hidden;

            @media (min-width: 1199px) {
                width: 135px;
                height: 100px;
            }
        }

        img { width: 100%; height: 100%; object-fit: contain; object-position: center; }
    }

    .user-info {
        float: left;
        width: 117px;
        padding: 5px;

        a {
            color: $color-text;
        }
    }

    .user-status {
        font-size: 12px;
        display: flex;
        justify-content: space-between;

        .user-online-status {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 5px;

            &.offline {
                background: #e91c23;
            }

            &.online {
                background: #139e00;
            }
        }
    }

    .user-broj-artikala {
        display: inline-block;
        background: #eee;
        border-radius: 4px;
        margin: 9px 10px;
        width: 100px;
        text-align: center;

        a {
            padding: 3px 5px;
        }

        @include hover-active-focus {
            background: $color-primary;

            a {
                color: $color-white;
            }
        }
    }

    .user-lokacija {
        display: flex;

        i {
            vertical-align: middle;
            margin-right: 3px;
        }

        p {
            display: inline-block;
            vertical-align: middle;
            line-height: normal;
        }
    }

    &.pro-user {
        background: #fffff0;

        .user-header {
            background-color: #ffd740;

            &::after {
                content: '';
                background: url(/img/sprite.png) no-repeat;
                background-position: 0px -40px;
                width: 34px;
                height: 12px;
                display: inline-block;
                float: left;
                margin: 2px;
            }
        }
    }

    &.even {
        margin-right: 33.5%;
    }
}

.zastupnik-item {
    @extend .user-item;

    width: 49.5%;

    .user-content {
        position: relative;
    }

    .user-info {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 75px;
    }

    .user-broj-artikala {
        width: 65px;
        margin: 0;
    }
    
    .zastupnik-brands {
        position: absolute;
        right: 0;
        display: flex;
        flex-flow: row-reverse wrap;
        width: 140px;
        justify-content: space-evenly;
    }

    .user-lokacija {
        padding: 0 2px;
    }
}

.pretraga-oglasa-prodavnice-header {
    @extend .pretraga-prodavnica-header;
    padding: 15px;
    padding-bottom: 0;
    float: left;
    width: 100%;
}

/**
 *
 * ADRESAR STYLES
 *
 */

.registruj-firmu {
    color: $color-text;
    font-weight: bold;
    text-decoration: underline;

    @include hover-active-focus {
        text-decoration: underline;
        color: $color-primary;
    }
}

.pretraga-adresar-form {
    @extend .pretraga-prodavnica-form;

    select {
        margin-right: 5px;
        margin-top: 5px;
        width: 214.5px;

        &:last-of-type {
            margin-right: 0;
        }
    }

    input[type="text"] {
        width: 182px;
    }
}

.adresar-content {
    @extend .prodavnice-content;
}

.adresar-item {
    @extend .user-item;

    &.pro-adresar {
        @extend .pro-user;
    }
}

.adresar-header {
    @extend .user-header;
}

.adresar-content {
    @extend .user-content;

    .adresar-img {
        @extend .user-img;
    }

    .adresar-status {
        @extend .user-status;
    }

    .adresar-online-status {
        @extend .user-online-status;
    }

    .adresar-broj-oglasa {
        @extend .user-broj-artikala;

        a {
            font-family: 'Trebuchet MS';
            font-size: 12px;
            padding: 3px 1px !important;

        }
    }

    .adresar-lokacija {
        @extend .user-lokacija;
    }
}

.pretraga-adresara {
    @extend .pretraga-prodavnica;
    padding-bottom: 0;
}

.adresar-map {
    border: 1px solid #101010;
    border-top: 0;
}

.adresar-map-akcije {
    position: absolute;
    width: 56px;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .4);

    div {
        width: 46px;
        height: 46px;
        background: url('/img/sprite-adresar.png');
        margin: 3px auto;
        cursor: pointer;
    }

    .sve {
        @include hover-active-focus {
            background-position: -46px 0;
        }
    }

    .servis {
        background-position: 0 -46px;
        @include hover-active-focus {
            background-position: -46px -46px;
        }
    }

    .otkup {
        background-position: 0 -92px;
        @include hover-active-focus {
            background-position: -46px -92px;
        }
    }

    .prodavci {
        background-position: 0 -138px;
        @include hover-active-focus {
            background-position: -46px -138px;
        }
    }

    .t-mobile {
        background-position: 0 -184px;
        @include hover-active-focus {
            background-position: -46px -184px;
        }
    }

    .telenor {
        background-position: 0 -230px;
        @include hover-active-focus {
            background-position: -46px -230px;
        }
    }

    .m-tel {
        background-position: 0 -276px;
        @include hover-active-focus {
            background-position: -46px -276px;
        }
    }

}

.adresar-pregled-header {
    @extend .prodavnice-header;
    float: left;
    width: 100%;
}

.adresar-pregled-content {
    float: left;
    width: 100%;
    padding: 10px;
    border: 1px solid #c0c0c0;

    .adresar-img {
        width: 200px;
        float: left;
        height: 100%;

        a {
            color: $color-text;
            width: 200px;
            margin-top: 3px;
            background: #eee;
            padding: 2px 5px;
            border-radius: 3px;
            line-height: 24px;

            i {
                font-size: 18px;
                vertical-align: middle;
                margin-right: 5px;
            }

            @include hover-active-focus {
                background: $color-primary;
                color: $color-white;
            }
        }
    }

    .adresar-info {
        float: left;
        width: 450px;
        margin-left: 10px;

        p {
            text-align: justify;
        }

    }
}

.adresar-info-header {
    text-transform: uppercase;
    margin-bottom: 5px;

    p {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 3px;
    }

    li {

        &::after {
            content: '/';
            color: $color-primary;
            margin: 0px 5px;
        }

        &:last-of-type {
            &::after {
                display: none;
            }
        }
    }

}

.adresar-oglasi {
    margin-top: 0;

    .oglasi-content {
        height: auto;
    }

    .oglasi-filter {
        margin-top: 0;
    }

    .oglasi-content-ikonice {
        display: none; // REMOVE THIS
    }

    .oglasi-content-list {
        display: none;
        // display: block;
    }
}

.fotografije-content {
    width: 100%;
    float: left;
    margin-top: 3px;
}

.fotografije-item {
    width: 170px;
    margin-right: 4px;
    float: left;
    margin-bottom: 4px;

    &:nth-of-type(4n) {
        margin-right: 0;
    }
}

.oglasi-adresar-osnovne-informacije {
    min-height: 335px;

    .oglasi-opis {
        padding-left: 0px;
    }
}

.oglasi-adresar-tab-header {

    li {
        width: 230px;

        &:last-of-type {
            width: 230px;
        }
    }
}

.oglasi-adresar-content-oglas {
    height: auto;
}

/*
 * PORUKE STYLE
*/

.poruke-header {
    @extend .page-header;
    float: left;
    width: 100%;
}

.poruke-pretraga {
    width: 100%;
    background: #eee;
    border: 1px solid #d9d9d9;
    padding: 5px;

    .link-nazad {
        height: 34px;
        height: 34px;
        background: $color-white;
        color: $color-text;
        text-transform: uppercase;
        border: 1px solid #d9d9d9;
        font-family: 'Trebuchet MS';
        font-weight: bold;
        padding: 5px;
        float: right;
        line-height: 23px;

        i {
            font-size: 20px;
            margin-right: 5px;
            margin-top: -3px;
        }

        @include hover-active-focus {
            color: $color-white;
            background: $color-primary;
        }
    }

    .link-do-oglasa {
        height: 34px;
        background: $color-white;
        color: $color-text;
        border: 1px solid #d9d9d9;
        font-family: 'Trebuchet MS';
        font-weight: bold;
        padding: 5px 10px;

        i {
            font-size: 20px;
            margin-right: 5px;
        }

        @include hover-active-focus {
            color: $color-white;
            background: $color-primary;
        }
    }

    option {
        height: 20px;
        border: 1px solid #d9d9d9;
    }

    select, option {
        font-weight: bold;
        width: 220px;
        border-radius: 0;
        text-transform: uppercase;
        font-family: 'Trebuchet MS';

        option {
            position: relative;

            span {
                &::after {
                    content: "aaa";
                    position: absolute;
                    left: 5px;
                    top: 1px;
                    width: 10px;
                    height: 10px;
                    font-size: 15px;
                }

            }
        }
    }

    .input-pretraga {
        display: inline-block;
        float: right;

        input {
            height: 34px;
            padding: 10px;
            width: 295px;
            border: 1px solid #c0c0c0;
        }

        button {
            background: none;
            border: 0;
            margin-left: -10px;
            cursor: pointer;
            line-height: 32px;
        }

        i {
            background: $color-text;
            width: 34px;
            height: 34px;
            color: $color-white;
            text-align: center;
            font-size: 24px;
        }
    }
}

.poruke-akcije {
    float: left;
    width: 100%;
    padding: 5px;
    padding-right: 13px;
    background: #f9f9f9;
    border: 1px solid #d9d9d9;
    border-top: 0;

    a {
        vertical-align: bottom;
    }

    label {
        color: $color-text;
        vertical-align: middle;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            height: 15px;
            width: 1px;
            background: #d9d9d9;
            margin: 0 10px;
            top: 2px;
        }
    }

    input[type='checkbox'] {
        color: $color-text;
        vertical-align: middle;
        margin-right: 7px;
    }
}

.poruke-akcije-left {
    float: left;

    p {
        display: inline-block;
        position: relative;

        &:nth-of-type(2) {
            margin-left: 13px;
        }

        &:first-of-type {
            margin-left: 4px;

            &::after {
                content: '';
                position: absolute;
                height: 15px;
                width: 1px;
                background: #d9d9d9;
                margin: 0 7px;
                top: 2px;
            }
        }
    }

    a {
        &:last-of-type {
            color: #999;
            margin-left: 20px;

            @include hover-active-focus {
                color: $color-text;
            }
        }

        &.oznaci-kao-neprocitano {
            margin-left: 5px;
            color: #333;

            &.neprocitano {
                color: #0077b5;
            }

            @include hover-active-focus {
                color: #0077b5;
            }

            i {
                margin-right: 5px;
            }
        }
    }

}

.poruke-akcije-right {
    float: right;

    p {
        display: inline-block;
        vertical-align: middle;
    }

    i {
        vertical-align: top;
        margin-right: 3px;
    }

    a {
        color: #333;
    }

    .poruke-akcija-sacuvaj {
        display: inline-block;
        margin-right: 15px;

        a {
            @include hover-active-focus {
                color: #ffa200;
            }
        }
    }

    .poruke-akcija-obrisi {
        display: inline-block;

        a {
            @include hover-active-focus {
                color: #e91c23;
            }
        }
    }

}

.poruke-content {
    width: 100%;
    float: left;

    .poruke-item {
        width: 100%;
        float: left;
        height: 47px;
        background: #f9f9f9;
        border: 1px solid #d9d9d9;
        border-top: 0;
        position: relative;

        a {
            color: $color-text;
            width: 100%;
            padding: 5px;
            padding-right: 13px;
            display: block;
            height: 100%;

            @include hover-active-focus {
                background: #f0f0f0;

                .poruke-item-vreme {
                    display: none;
                }

                .poruke-item-akcije {
                    display: block;
                }
            }
        }

        &.nova-poruka {
            background: #fffff0;
        }
    }
}

.poruke-item-img {

    float: left;
    width: 70px;

    input {
        vertical-align: middle;
    }

    i {
        font-size: 40px;
        margin-top: -9px;
    }

    img {
        margin-top: -2px;
        border-radius: 5px;
    }
}

.poruke-item-content {
    float: left;
    width: 500px;
    margin-top: -3px;

    .user-online-status {
        width: 11px;
        height: 11px;
        display: inline-block;
        border-radius: 50%;
        margin-right: 4px;
        vertical-align: middle;

        &.offline {
            background: #e91c23;
        }

        &.online {
            background: #139e00;
        }
    }

    .poruke-item-content-user-status {
        p {
            font-family: 'Trebuchet MS';
            font-weight: bold;
            display: inline-block;
            vertical-align: middle;
        }
    }

    .poruke-item-content-text {
        color: #999;

        .naslov-poruke {
            color: $color-text;
        }
    }

}

.poruke-item-vreme {
    float: right;
    margin-top: 9px;
    margin-right: 4px;

    p {
        color: #333;
        font-size: 13px;
    }
}

.poruke-item-akcije {
    float: right;
    margin-top: 9px;
    margin-right: 4px;

    display: none;

    span {
        margin-left: 15px;

        &:first-of-type {
            @include hover-active-focus {
                i {
                    color: #ffa200;
                }
            }
        }

        &:last-of-type {
            @include hover-active-focus {
                i {
                    color: #e91c23;
                }
            }
        }

        i {
            color: #666;
            font-size: 16px;
            vertical-align: middle;
        }
    }

}

.poruke-konverzacija-content {
    float: left;
    width: 100%;
    padding: 10px;
    border: 1px solid #d9d9d9;
    border-top: 0;
}

.moje-poruke-msg {

    .oglasi-pitanje-msg {
        width: 481px !important;
    }

    .oglasi-tab-odgovor {
        float: left;
        width: 100%;

        &::before {
            display: none;
        }
    }

    .oglasi-pitanje-msg {
        width: 481px !important;

        button {
            margin-right: 8px;
            padding: 5px 30px !important;
        }

    }

    .oglasi-pitanje-msg-footer {
        a {
            color: $color-text;
            margin-left: 8px;
            margin-top: 2px;

            i {
                margin-right: 5px;
                font-size: 18px;
                vertical-align: bottom;
            }

            @include hover-active-focus {
                color: $color-primary;
            }
        }
    }

    .prilog-slike {
        border-top: 1px solid #d9d9d9;
        padding-top: 10px;
        padding-bottom: 5px;
        margin-top: 5px;

        .prilog-slike-item {
            margin-right: 2px;
            display: inline-block;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }
}

.javna-pitanja-item {
    float: left;
    width: 100%;
    height: 63px;
    border: 1px solid #d9d9d9;
    border-top: 0;

    &.neodgovoreno-pitanje {
        a {
            background: #fffff0;
        }

        .javna-pitanja-status {
            color: #e91c23;
        }
    }

    a {
        padding: 3px;
        background: #f9f9f9;
        width: 100%;
        color: $color-text;

        @include hover-active-focus {
            background: #f0f0f0;
        }
    }

}

.javna-pitanja-item-content {
    @extend .poruke-item-content;
    width: 350px;
    margin-left: 3px;
    padding-top: 3px;

}

.javna-pitanja-item-img {
    float: left;
    width: 77px;
}

.javna-pitanja-status,
.javna-pitanja-vreme {
    float: left;
    width: 125px;

    p {
        line-height: 56px;

    }
}

.javna-pitanja-status {
    text-align: center;
}

.javna-pitanja-vreme {
    text-align: right;
}

.poruke-akcije-javna-pitanja {
    padding-right: 5px;

    .poruke-akcije-right {
        p {

            &:first-of-type {
                margin-right: 125px;
            }
        }

    }

}

.heading-lead {
    width: 100%;
    margin-bottom: 10px;
}

.form-group-fullWidth {
    width: 100% !important;

    input {
        width: 100% !important;
    }

    &__error-text {
        color: #e91c23;
        font-weight: bold;
        font-size: 1rem;
        padding: 2px;
    }
}

.input-addon {
    background: #ccc;
    color: $color-text;
    float: left;
    width: 40px;
    height: 34px;
    position: absolute;
    right: 0;
    // bottom: 0;
    text-align: center;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    line-height: 32px;
}

.oglas-form-header {
    width: 100%;
    float: left;
    color: $color-primary;
    text-transform: uppercase;
    font-family: 'Trebuchet MS';
    font-weight: bold;
    font-size: 14px;
    margin-top: 25px;
    margin-bottom: 20px;
    border-bottom: 1px solid $color-text;
    padding-bottom: 5px;
}

.checkbox-tooltip {
    display: inline-block;
    vertical-align: top;
    margin-left: 3px;
    font-size: 12px;
    cursor: pointer;
    position: relative;

    .cb-tooltip-content {
        display: none;
        position: absolute;
    }

    @include hover-active-focus {
        .cb-tooltip-content {
            display: block;
            font-size: 14px;
            padding: 5px;
            border-radius: 3px;
            position: absolute;
            width: 200px;
            top: 20px;
            left: 20px;
            z-index: 9999;
            background: rgba(0, 0, 0, 0.7);
            color: #fff;
        }
    }

}

.proizvodjac-form-group {
    margin-left: 0;
    margin-right: 26px !important;

    &.first-type {
        margin-left: 26px;
    }

    &.last-type {
        margin-right: 0 !important;
    }
}



.dodaj-model-btn {
    float: left;
    color: $color-text;
    display: inline-block;
    height: 34px;
    padding-top: 8px;

    @include hover-active-focus {
        color: $color-primary;
    }

    span {
        color: $color-primary;
        font-weight: bold;
    }
}

.obrisi-model-btn {
    float: left;
    display: inline-block;
    color: $color-text;

    &:hover {
        color: #e91c23;
    }

    &:active {
        color: #e91c23;
    }

    span {
        color: #e91c23;
        font-weight: bold;
    }
}

.form-group-file {
    float: left;
    width: 100%;
    min-height: 116px;
    background: #f9f9f9;
    border: 2px dashed #c0c0c0;
    font-family: 'Trebuchet MS';
    font-weight: bold;
    text-align: center;
    position: relative;

    a {
        position: relative;
        background: $color-text;
        color: $color-white;
        padding: 13px;
        border-radius: 3px;
        margin-right: 5px;

        @include hover-active-focus {
            background: $color-primary;
        }
    }
}

.napomena-slike {
    float: left;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 20px;
}

.tipovi-oglasa {
    float: left;
    width: 100%;
    display: flex;
}

.tip-oglasa-item {
    background: #f9f9f9;
    border: 1px solid #c0c0c0;
    position: relative;
    margin-bottom: 3px;
    width: 33%;
    margin-right: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
        cursor: pointer;
        background: #101010;
        color: #fff;
        padding: 8px 30px;
        border-radius: 3px;
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 10px;

        &.active {
            background: $color-primary;
        }

        @include hover-active-focus {
            background: $color-primary;
        }
    }
}

.tip-oglasa-naziv {
    width: 100%;
    color: #ff7800;
    text-transform: uppercase;
    font-size: 26px;
    font-weight: bold;
    text-align: center;
    border-top: 1px solid #c0c0c0;
    border-bottom: 1px solid #c0c0c0;
    padding: 3px 10px;
    margin-top: 15px;
    background: $color-white;
}

.tip-oglasa-cena {
    text-align: center;
    font-size: 60px;
}

.tip-oglasa-lista {
    padding: 0px 10px;
    min-height: 80px;

    li {
        margin-bottom: 10px;
        display: block;

        span {
            position: relative;
            color: #ff7800;
            font-weight: bold;
            border-bottom: 1px solid #ff7800;
        }
    }
}


.btn-oglas-submit {
    float: left;
    width: 100%;
    font-family: 'Trebuchet MS';
    font-size: 22px;
    font-weight: bold;
    background: $color-text;
    border: 0;
    outline: 0;
    box-shadow: 0;
    cursor: pointer;
    text-transform: uppercase;
    color: $color-white;
    padding: 10px;
    border-radius: 3px;

    @include hover-active-focus {
        background: $color-primary;
    }
}

.dodaj-logo-group, .dodaj-banner-group {
    width: 100%;
    float: left;
    overflow: hidden;
    position: relative;
}

.dodaj-logo-group {
    height: 150px;
}

.logo-img {
    width: 200px;
    float: left;

    img {
        max-height: 150px;
    }
}

.logo-btns, .banner-btns {
    float: right;

    a, label {
        font-weight: 600;
        font-size: 16px;
        color: $color-text;
        background: #eee;
        line-height: 24px;
        border: 1px solid #d9d9d9;
        cursor: pointer;

        @include hover-active-focus {
            background: $color-primary;
            color: #fff;
        }

        i {
            margin-right: 5px;
            vertical-align: bottom;
            font-size: 20px;
        }
    }
}

.logo-btns {
    width: 115px;

    a, label {
        display: block;
        margin-bottom: 5px;
        margin-left: 5px;
        padding: 5px 5px 5px 15px;
    }
}

.banner-btns {
    width: 210px;

    a, label {
        display: inline-block;
        margin: 5px;
        padding: 5px 15px 5px 15px;
    }
}

.banner-img {
    width: 315px;
    float: left;

    img {
        max-width: 100%;
    }
}

.dodaci-header {
    margin-bottom: 20px;

    h3 {
        font-size: 20px;
    }
}

.dodaci-group {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;

    .tip-oglasa-item {
        width: 33%;
        margin-right: 3px;

        a {
            &.active, &.pending {
                background: $color-primary;
                cursor: not-allowed;
            }

            &.pending {
                text-align: center;
                cursor: not-allowed;
                text-align: center;
                width: 9em;
            }

            &.disabled {
                cursor: not-allowed;
            }
        }
        
        &:nth-of-type(3n) {
            margin-right: 0px;
        }
    }

    &-heading {
        width: 100%;
        margin: 30px 0;
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
    }
}

.tip-oglasa-lista {
    .pro {
        padding-left: 40px;
        border: none;

        &::after {
            content: '';
            background: url(/img/sprite.png) no-repeat;
            background-position: 0px -40px;
            width: 34px;
            height: 12px;
            position: absolute;
            top: 3px;
            left: 0;
        }
    }

}

.registracija-adresar {
    .info-ime {
        border: none;
        padding: 0;
        float: left;
        width: 100%;
        position: relative;
        left: 0;

        textarea {
            background: #f9f9f9;
            border: 1px solid #c0c0c0;
            border-radius: 3px;
            resize: none;
            width: 100%;
            height: 120px;
        }
    }

    .fotografije-group {
        .item {
            width: 32%;
            margin-right: 2%;
            float: left;
            margin-bottom: 5px;
            margin-left: 0;

            &:nth-of-type(3n) {
                margin-right: 0;
            }

            .rotate-btn {
                cursor: pointer;
            }

            .remove-btn {
                cursor: pointer;
            }

            .ico-novost-pregledi {
                font-size: 12px;
                cursor: pointer
            }

            .img-container {
                height: 76px;
            }
        }
    }
}

.adresar-info {
    border: none;
    padding: 0;
    float: left;
    width: 100%;
    position: relative;
    left: 0;
    display: block !important;

    textarea {
        background: #f9f9f9;
        border: 1px solid #c0c0c0;
        border-radius: 3px;
        resize: none;
        width: 100%;
        height: 120px;
        overflow: hidden;
    }
}

.radno-vreme-od, .radno-vreme-do {
    float: left;
    width: 100%;
    margin-bottom: 15px;

    .item {
        width: 30% !important;
        float: left;
        margin-right: 5%;

        &:last-of-type {
            margin-right: 0;
        }

        input, select {
            width: 100%;
        }
    }
}

.btn-dodaju-adresu {
    margin-bottom: 15px;
}

.ads-filter {
    position: relative;

    &__row {
        display: inline-block;

        &::after {
            display: block;
            content: '';
            clear: both;
        }

        optgroup {
            color: #a395a4;
            font-weight: 300;

            option {
                color: #666;
            }
        }
    }

    &__search-button {
        float: right;
    }

    &__select_multiple {
        background: none;
    }

    &__spinner {
        width: 100%;
        height: 100%;
        z-index: 5;
        background: white;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.7;
    }

    &__select-dropdown {
        display: block;
        position: absolute;
        width: 100%;
        background: #f9f9f9;
        margin-top: -2px;
        border: 1px solid #c0c0c0;
        border-top: none;
        z-index: 5;

        ul {
            max-height: 200px;
            overflow: scroll;
        }

        li {
            display: block;
            padding: 3px 5px;

            @include hover-active-focus {
                background: dodgerblue;
                color: #fff;
                cursor: default;
            }
        }
    }

    &__groupName {
        padding: .4em 1em;
    }

    &__checkbox {
        height: 49px;
        display: flex;
        align-items: center;
    }
}

.logout-button {
    cursor: pointer
}

.dz-message {
    margin-top: 35px;
}

.dz-progress {
    display: none;
}

.profile-notifications {
    &__header {
        float: left;
        width: 100%;
    }
}

.removeBtn {
    float: right;
    padding-top: 8px
}

.page__not-found {
    text-align: center;
    font-size: 35px;
    padding-top: 40px;
    float: left;
    width: 100%;
}

.hide-btn {
    &_visible {
        color: #000000;

        &:hover {
            color: #999999;
        }
    }

    &_hidden {
        color: #999999;

        &:hover {
            color: #000000;
        }
    }
}

.ad-status {
    position: absolute;
    right: 3px;
    bottom: 5px;

    &__item {
        font-size: 11px;
        font-weight: 700;
        border-radius: 2px;
        padding: 0 2px;
        font-family: 'Open Sans';
        line-height: 13px;
        display: inline-block;
        float: right;
        clear: right;

        &_paid {
            background-color: rgba(233, 28, 35, 0.6);
            color: #ffffff
        }
    }
}

.ads-loading {
    min-height: 125px;
    position: relative;
    float: left;
    width: 100%;
}

.ads-loading-text {
    font-size: 36px;
    padding: 10px;
    text-align: center;
}

.sms-ads {
    &__type {
        width: 20%;
        display: inline-block;
    }

    &__group {
        width: 20%;
        display: inline-block;
    }

    &__search {
        display: inline-block;
        width: 55%;
        height: 34px;
    }
}

.report-form {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: flex-start;

  @include for-mobile-and-tablet {
    flex-direction: column;
  }
}

.report-column {
  width: 50%;

  @include for-mobile-and-tablet {
    width: 100%;
  }
}

.report-row {
    padding: 10px;

    [type="radio"] {
        padding: 3px;
        margin-right: 5px;
    }

    em {
        font-weight: 700;
        color: #D05;
    }
}

.report-buttons {
    button {
        font-family: 'Trebuchet MS';
        font-size: 16px;
        font-weight: bold;
        border: 0;
        outline: 0;
        cursor: pointer;
        text-transform: uppercase;
        color: #fff;
        padding: 10px;
        border-radius: 3px;
    }
}

.report-button {
    &__close {
        background: #101010;
        margin-right: 2px;

        &:hover {
            opacity: 0.8;
        }
    }

    &__submit {
        background: $color-primary;

        &:hover {
            opacity: 0.8
        }
    }
}

.report-comment, .report-email {
    padding: 15px 0 15px 15px;
}

.report-comment textarea {
    width: 100%;
}

.report-email input {
    width: 100%;
    padding: 4px 6px;
}

.report-buttons {
    float: right;
}

.grecaptcha-badge { visibility: hidden; }
