.active-addons {
	.registracija-content-form {
		@include for-mobile-and-tablet {
			padding: 0 .2rem;
		}
	}

	.registracija-content-heading {
		@include for-mobile-and-tablet {
			margin-bottom: 0;
		}
	}

	.dodaci-group-heading {
		@include for-mobile-and-tablet {
			margin: 15px 0;
		}
	}

	.tip-oglasa-item {
		@include for-mobile {
			width: 49.5%;
		}

		&:nth-of-type(2n) {
			@include for-mobile {
				margin-right: 0;
			}
		}
	}
}