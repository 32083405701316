.vehicle-part-container {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	padding: 0 0 3rem 0;

	@include for-mobile-and-tablet {
		flex-direction: column;
		padding-left: 0;
	}
}

.vehicle-part-header {
	width: 100%;
	display: flex;
	align-items: center;

	&__image-container {
		cursor: pointer;
		position: relative;
		width: 20rem;
		height: 20rem;
		overflow: hidden;
		border: 1px solid #ddd;
		display: flex;
		align-items: center;
		justify-content: center;

		@include for-mobile-and-tablet {
			width: 100%;
			height: auto;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	@include for-mobile-and-tablet {
		flex-direction: column;

		&__image-container {
			width: 100%;
		}
	}

	&__image-count {
		position: absolute;
		bottom: 0;
		right: 0;
		background: rgba(0, 0, 0, .5);
		padding: 1px 6px;
		color: #fff;

		&::after {
			content: '';
			position: absolute;
			left: -4px;
			top: 0;
			width: 0;
			height: 0;
			border-bottom: 28px solid rgba(0, 0, 0, .5);
			border-left: 4px solid transparent;
		}

		p {
			display: inline-block;
			font-family: 'Tahoma';
			font-weight: bold;
			font-size: 18px;
			vertical-align: middle;
		}

		i {
			margin-left: 5px;
			vertical-align: middle;
		}
	}
}

.vehicle-part-header-info {
	width: 60%;
	margin: 1rem 1rem 1rem 2rem;
	margin-left: auto;
	font-size: 1rem;

	@include for-mobile-and-tablet {
		width: 100%;
		margin: .5rem;
		padding: 0 0.5rem 0 0.5rem;
	}

	&__title {
		font-size: 1.75rem;
		margin: 0.5rem 0;
		line-height: normal;

		@include for-mobile-and-tablet {
			font-size: 1.5rem;
		}
	}

	&__status {
		display: flex;

		span {
			font-weight: bold;
			margin-right: 25px;
		}

		&--text-na-stanju::before {
			content: '';
			display: inline-block;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: green;
			margin-right: 5px;
		}

		&--text-nije-na-stanju::before {
			content: '';
			display: inline-block;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: red;
			margin-right: 5px;
		}

		&--discount {
			height: 23px;
			display: inline-flex;
			text-transform: uppercase;
			position: relative;
			background-color: $color-secondary;
			color: white;
			font-weight: bold;
			padding: 2px 5px 2px 0;
			width: 6.5rem;
			align-items: center;
			justify-content: center;
		}

		span &--discount-circle {
			margin-right: auto;
			width: 6px;
			height: 6px;
			background-color: #FFF;
			border-radius: 6px;
		}

		&--discount::before {
			content: "";
			display: block;
			height: 23px;
			width: 29px;
			position: absolute;
			top: 0;
			left: -29px;
			background: $color-secondary;
			clip-path: polygon(50% 50%, 100% 0%, 100% 100%, 50% 50%);
		}
	}

	&__code {
		margin-top: 0.5rem;

		p {
			font-size: 1;
			font-weight: bold;
			text-transform: uppercase;
		}
	}

	&__price {
		margin-top: 0.5rem;

		display: flex;
		align-items: center;

		border-top: 1px solid #aaa;
		border-bottom: 1px solid #aaa;

		padding: .75rem 0;

		p {
			text-transform: uppercase;
			margin-right: 5%;
		}

		&--old {
			font-size: 2rem;
			font-weight: bold;
			color: #aaa;
			text-decoration: line-through;
		}

		&--new {
			font-size: 2rem;
			font-weight: bold;
			color: #FF0000;
		}

		&--pdv {
			font-size: 1rem;
			color: #aaa;
			text-transform: uppercase;
		}
	}

	&__contact {
		margin-top: 0.5rem;

		display: flex;
		align-items: center;

		&--phone {
			display: flex;
			flex-direction: column;

			margin-left: 1rem;

			p {
				line-height: normal;
				text-transform: uppercase;

				&:first-of-type {
					font-size: 1.5rem;
					font-weight: normal;
				}

				&:last-of-type {
					font-size: 2rem;
					font-weight: bold;
				}
			}
		}
	}
}

.vehicle-part-shipping-and-payment-info {
	display: flex;

	@include for-mobile-and-tablet {
		flex-wrap: wrap;
	}

	width: 100%;

	margin-top: .5rem;

	&__item {
		border: 1px solid #ddd;
		background-color: $color-grey-light;
		width: 33.33%;
		padding: 1rem 0.5rem;

		@include for-mobile-and-tablet {
			width: 100%;
			padding: .5rem;
			height: 3.75rem;
		}

		display: flex;
		align-items: center;
		justify-content: center;

		p {
			font-size: 1rem;
			font-weight: bold;
			text-transform: uppercase;
			margin-left: 1rem;
			line-height: normal;
		}
	}
}

.vehicle-part-specifications {
	width: 100%;
	margin-top: .5rem;
	border: 1px solid #ddd;

	&__title {
		background-color: $color-grey-light;
		border-bottom: 1px solid #ddd;
		font-size: 1.5rem;
		font-weight: bold;
		padding: .25rem .5rem;
		margin: 0;

		&::after {
			display: none;
		}
	}

	&__item {
		display: flex;
		margin: .25rem;
		font-size: 1rem;
		padding: .25rem .5rem;

		&:nth-last-of-type(2n + 1) {
			background-color: $color-grey-light;
		}

		span {
			width: 50%;

			&:last-of-type {
				font-weight: bold;
			}
		}
	}

	&__compatible-brand {
		display: flex;
		margin: .25rem;
		font-size: 1rem;
		padding: .25rem .5rem;

		&:nth-last-of-type(2n + 1) {
			background-color: $color-grey-light;
		}

		span {
			margin-left: 5px;
		}
	}
}

.kategorije-sub-item {
	.shop-category-icon {
		.fil0 {
			fill: $color-text;
		}

		@include hover-active-focus {
			.fil0 {
				fill: $color-primary;
			}

			fill: $color-primary;
		}
	}

	&.active {
		.shop-category-icon {
			.fil0 {
				fill: $color-primary;
			}
		}
	}
}