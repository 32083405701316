.pretraga-prodavnica-form {

	.users__filter-row {
		@include for-mobile {
			width: 100%;
		}
	}

	.users-list__select {
		@include for-mobile {
			width: 100%;
			margin-right: 0;
			margin-bottom: .2rem;
		}
	}

	.users-list__search {
		@include for-mobile {
			width: 80%;
			margin-right: 0;
			margin-bottom: .2rem;
		}
	}

	button[type="submit"] {
		@include for-mobile {
			width: 20%;
			margin-left: 0;
		}
	}
}

.prodavnice-content {
	.user-item {
		@include for-mobile { width: 100%; }

		@include for-tablet {
			width: 49.5%;
			margin: 1px;
		}

		&.even {
			@include for-mobile { margin-right: 0; }
			@include for-tablet { margin-right: 0; }
		}

		&-banner {
			width: 100%;
			text-align: center;
		}
	}
}

.pretraga-prodavnica-header {
	@include for-mobile {
		display: flex;
		flex-direction: column;
	}
}