.sacuvani-korisnici {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	margin-top: 3px;
}

.sacuvani-korisnici-item {
	width: 24.5%;
	border: 1px solid #d9d9d9;
	padding: 3px 3px 3px 5px;
	position: relative;
	font-family: 'Trebuchet MS';
	margin-right: 3px;
	margin-bottom: 5px;

	&:nth-of-type(4n) {
		margin-right: 0;
	}

	.obrisi-sacuvanog-korisnika {
		position: absolute;
		right: 4px;
		top: 1px;
		color: $color-text;

		i {
			font-size: 16px;
		}

		@include hover-active-focus {
			color: #e91c23;
		}
	}
}

.sacuvani-korisnik-img {
	float: left;
	width: 60px;
	margin-right: 3px;
	min-height: 60px;

	i {
		font-size: 55px;
		margin-top: -7px;
	}

	img {
		margin-top: 6px;
	}
}

.sacuvani-korisnik-info {
	float: left;
	width: 100%;

	p {
		font-weight: bold;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.online-status {
		font-size: 12px;
		margin-top: 3px;

		&.online {
			span {
				background: #139e00;
			}
		}

		&.offline {
			span {
				background: #e91c23;
			}
		}

		span {
			width: 11px;
			height: 11px;
			display: inline-block;
			border-radius: 50%;
			vertical-align: middle;
			margin-right: .125rem;
		}
	}
}

.sacuvani-korisnik-lokacija-link {
	float: left;
	width: 100%;
	font-size: 12px;

	i {
		vertical-align: top;
	}

	p {
		display: inline-block;
		vertical-align: middle;
	}

	.sacuvani-korisnik-lokacija {
		float: left;
		margin-top: 5px;
	}

	.sacuvani-korisnik-link {
		float: right;

		a {
			color: $color-text;
			border: 1px solid #d9d9d9;
			background: #eee;
			padding: 5px 13px;
			border-radius: 3px;

			@include hover-active-focus {
				background: $color-primary;
				color: $color-white;
				border: 1px solid $color-primary;
			}
		}
	}
}