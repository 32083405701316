// Font directory, families, weights
$font-stack: 'Calibri', 'Open Sans', sans-serif;
$font-size:   14px;
$font-weight: 400;

h1 {

}

h2 {
    font-size: 14px;
    font-family: 'Trebuchet MS';
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    margin-bottom: 8px;

    &::after {
        content: '';
        height: 3px;
        width: 100%;
        display: block;
        background: $color-primary;
        margin-top: 5px;
    }
}

h3 {
    font-size: 14px;
    font-weight: bold;
    margin: 5px 0 0 0;
}

h4 {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 8px 0 0 0;
}

h5 {
    margin: 0;
    text-transform: uppercase;
    font-family: 'Trebuchet MS';
    font-weight: bold;
    font-size: 16px;
    color: $color-primary;
}

p {
	line-height: 145%;
	margin: 0;
}

strong {
	font-weight: 700;
}

i {
	display: inline-block;
}

a, button {
	display: inline-block;
	text-decoration: none;

	@include hover-active-focus {
			text-decoration: none;
	}
}