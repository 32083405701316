.category-search {
	width: 100%;
	display: flex;
	flex-wrap: nowrap;

	.pretraga-dropdown { width: 20%; float: none; }
	.sve-kategorije-btn { width: 100% }

	&-form {
		width: 80%;
		display: flex;

		.categories-suggestions {
			background-color: $color-grey-light;
			padding: .2rem 0;
			position: absolute;
			width: 96.5%;
			z-index: 10;
			left: 15px;
			top: 3.25rem;
	
			&-container {
				padding: .3rem;
				margin: 1rem .8rem;
	
				li {
					margin-right: .5rem;
					font-size: 14px;
	
					a {
						text-decoration: none;
						color: $color-text;
						cursor: default;
					}
	
					&:last-of-type {
						a {
							font-weight: bold;
						}
					}
				}
	
				@include hover-active-focus {
					color: $color-white;
					background-color: $color-primary;
					cursor: pointer;
	
					a {
						color: $color-white;
						cursor: pointer;
					}
				}
	
				&__active {
					color: $color-white;
					background-color: $color-primary;
					cursor: pointer;
	
					li {
						a {
							color: $color-white;
							cursor: pointer;
						}
					}
				}
			}
		}
	}

	input {
		width: 100%;
		height: 37px;
		background: #f9f9f9;
		padding: 10px;
		border: 1px solid #c0c0c0;
		border-left: 0;
		outline: none;
  }

	.ads-search__button {
		padding: 3px 6px 4px 7px;
		height: 37px;
		margin-left: -2px;
		cursor: pointer;
		background: $color-primary;
		border: 0;
		color: $color-white;
		font-size: 24px;
	}
}

.text-search-mobile {
	.category-search-form {
		display: block;

		.categories-suggestions {
			position: initial;
			width: 100%;
		}
	}

	.ads-search-input-container {
		display: flex;
	}
}