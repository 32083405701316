select,
input {
  font-family: 'Open Sans';
}

.inputWrapper {
  border-radius: 18px;
}

input[type="checkbox"] {
    -webkit-appearance: none;
    background-color: #f9f9f9;
    border: 1px solid #c0c0c0;
    padding: 6px;
    border-radius: 18px;
    display: inline-block;
    position: relative;
    margin: 1px 3px 0px 0px;
}

.oglasi-items-list-akcije {
    input[type="checkbox"] {
        top: 3px;
    }

    .akcija-sacuvaj {
        a {
            margin-bottom: 1px;
        }

        .star {
            margin-right: 2px;
        }
    }
}

input[type="checkbox"]:checked {
    border: 1px solid #c0c0c0;
    color: #c0c0c0;
}

input[type="checkbox"]:checked:after {
    content: '\E0E4';
    font-family: 'autodilericons';
    font-size: 9px;
    position: absolute;
    top: 1px;
    left: 1px;
    color: $color-text;
}

select, .singleselect, .multiselect {
	display: inline-block;
	width: 100%;
	background: url('/icons/022.pretraga-polja-dropdown-strelica.svg') 96% / 15% no-repeat #f9f9f9;
	background-size: 20px;
	border: 1px solid #c0c0c0;
	border-radius: 18px;
	padding: 8px;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	
	input[type="text"] {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		padding: 8px;
		border: 1px solid #c0c0c0;
		border-radius: 18px;
	}
}

select {
	option {
		width: 100px;
	}
}

.custom-select {
	padding: 8px;
	border: 1px solid #777;
	width: 25%;
	background: #fff;
	position: relative;
	cursor: pointer;
	z-index: 3;
	text-transform: uppercase;
	font-family: 'Trebuchet MS';
	font-weight: bold;
	background: url('/icons/022.pretraga-polja-dropdown-strelica.svg') 96%/15% no-repeat #f9f9f9;
	background-size: 20px;

	a {
		color: $color-text;
	}

	i {
		margin-right: 5px;
		font-size: 16px;
		margin-top: -3px;
		width: 20px;
		text-align: center;

		&.neprocitane-poruke-custom-select {
			font-size: 9px;
		}
	}

	ul {
			display: none;
			position: absolute;
			top: 100%;
			left: -1px;
			background: white;
			width: 100%;
			border-bottom: 1px solid #c0c0c0;

			li {
					display: block;
					padding: 8px;
					border: 1px solid #c0c0c0;
					border-bottom: 0;

					@include hover-active-focus {
							background: #eee;
					}
			}

	}

	@include hover-active-focus {
			ul {
					display: block;
			}
	}
}


.select-with-checkbox {
	width: 100%;
	position: relative;

	input[type="checkbox"] {
			vertical-align: top;
	}

	label {
		display: inline-block;
		width: 100%;
		height: 34px;
		background: url('/icons/022.pretraga-polja-dropdown-strelica.svg') 96%/15% no-repeat #f9f9f9;
		background-size: 20px;
		border: 1px solid #777;
		border-radius: 18px;
		padding: 8px;
	}

	> input[type="checkbox"] {
		display: none;

		&:checked + .select-dropdown {
			display: block;
			position: absolute;
		}
	}

	.select-dropdown {
		width: 100%;
		background: #f9f9f9;
		position: absolute;
		margin-top: -2px;
		border: 1px solid #c0c0c0;
		z-index: 5;

		li {
			display: block;
			padding: 3px 5px;

			@include hover-active-focus {
				background: dodgerblue;
				color: #fff;
				cursor: default;
			}
		}
	}
}

.singleselect, .multiselect {
	position: relative;
	&__select-dropdown {
		position: relative;
		width: 100%;
		
		ul {
			position: absolute;
			top: 0;
			width: 100%;
			height: 251px;
			border: 1px solid #c0c0c0;
			background-color: #f9f9f9;
			padding: 2px;
			overflow: scroll;
			z-index: 1;

			li {
				display: block;
				margin: 2px;
				cursor: pointer;
			}
		}
	}

	&__select-dropdown-grouped {
		position: absolute;
		width: 100%;
		height: 251px;
		border: 1px solid #c0c0c0;
		background-color: #f9f9f9;
		padding: 2px;
		overflow: scroll;
		z-index: 1;

		ul {
			li {
				display: block;
				padding: 3px;
			}
		}
		
		.group-label {
			font-weight: bold;
			display: flex;
			align-items: center;
		}

		.select-all {
			position: absolute;
			right: 7px;
			background: $color-bg;
			padding: 0 8px;
			cursor: pointer;
			color: $color-white;
			border-radius: 18px;

			&:hover, &.selected {
				background-color: $color-primary;
			}
		}

		.group-items {
			.option {
				display: block;
				padding: 3px;
				margin: 2px;
				cursor: pointer;
			}
		}

		li {
			list-style: none;
		}
	}

	&__select-dropdown, &__select-dropdown-grouped {
		.option {
			position: relative;
			label {
				width: 100%;
				padding: 3px;
				display: flex;
				align-items: center;
				&::before {
					content: '';
					width: 6px;
					height: 6px;
					display: inline-block;
					border: 1px solid black;
					border-radius: 6px;
					margin-right: 3px;
					padding: 2px;
					background: $color-bg;
					background-clip: content-box;
				}
			}

			input[type="checkbox"] {
				width: 100%;
				height: 100%;
				cursor: pointer;
				position: absolute;
				opacity: 0;
				&:hover + label, &:checked + label {
					color: $color-white;
					background-color: $color-bg;
					&::before {
						content: '';
						padding: 1px;
						border: 2px solid #f9f9f9;
						box-shadow: 0 0 0 1px black;
					}
				}

				&:focus + label {
					background-color: $color-bg;
					&::before {
						background: $color-bg;
					}
				}

				&:checked + label {
					background-color: $color-primary;
					&::before {
						background: $color-primary;
					}
				}
			}
		}
	}
}

.singleselect {
	&__select-dropdown, &__select-dropdown-grouped {
		.option {
			label {
				&::before {
					display: none;
				}
			}
		}
	}
}

.select-wrap {
	display: inline-block;
	border: 1px solid #777;
	width: 100%;
	height: 2.5rem;
	position: relative;
	background: url('/icons/022.pretraga-polja-dropdown-strelica.svg') 96% / 15% no-repeat #f9f9f9;
	background-size: 20px;
	background-position-x: 45px;

	label {
		font-size: 16px;
		text-align: center;
	}
}

.confirm-dialog {
	min-width: 246px;
	button {
		width: 5rem;
    margin-left: 1.6rem;
		color: $color-text;
		background: #eee;
		border: 1px solid #d9d9d9;
		padding: 3px 10px;
		text-align: center;
		font-family: 'Trebuchet MS';
		font-weight: bold;
		cursor: pointer;

		@include hover-active-focus {
				color: $color-white;
				background: $color-primary;
		}
	}
}
