.under-construction-page {
	width: 100%;

	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-direction: column;

	p {
		margin-top: 2%;
		font-size: 26px;
	}
}