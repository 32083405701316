.select-dropdown-picker {
	position: relative;
	display: inline-block;
	width: 100%;
	height: 2.8rem;
	// background: url('/icons/022.pretraga-polja-dropdown-strelica.svg') 94% / 15% no-repeat #FAFAFA;
	// background-size: 20px;
	background-color: #FAFAFA;
	border: 1px solid #BBB;
	padding: 11px;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
		
	input[type="text"],
	input::placeholder {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 2.4rem;
		padding: 10px;
		border: 1px solid transparent;
		border-radius: 20px;
		outline: none;
		font-size: 14px;
		padding-left: 1rem;
	}

	&>label {
		display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 90%;
	}

	&>label.select-dropdown-selected {
		font-family: 'Trebuchet MS';
		font-weight: bold;
		font-size: 16px;
	}

	&.disabled {
		cursor: not-allowed;
		opacity: .7;

		label {
			cursor: not-allowed;
		}
	}

	&__icon-svg {
		position: absolute;
		right: .25rem;

		.fil0 {
			fill: #BBB;
		}
	}
}


.select-dropdown-container {
	position: relative;
	width: 100%;
	
	.select-dropdown-items {
		position: absolute;
		top: 0;
		width: 100%;
		height: 251px;
		border: 1px solid #c0c0c0;
		background-color: #f9f9f9;
		padding: 2px;
		overflow: auto;
		z-index: 2;
	}

	.select-dropdown-item {
		display: block;
		position: relative;
		margin: 2px;
		cursor: pointer;

		&-label {
			display: block !important;
			padding: 3px;
			align-items: center;
			user-select: none;
		}

		input[type="checkbox"] {
			width: 100%;
			height: 100%;
			cursor: pointer;
			position: absolute;
			opacity: 0;

			&:hover + label, &:checked + label {
				color: $color-white;
				background-color: $color-text;
			}

			&:checked + label {
				background-color: $color-primary;
			}
		}
	}

	.select-dropdown-group, .select-dropdown-subgroup{
		display: block;
		margin: 3px 0 3px 3px;
	}

	.select-dropdown-subgroup {
		margin-bottom: 1vh;
	}

	.select-dropdown-subgroup-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: $color-white;
		position: relative;
		padding: .25rem 0 .25rem .25rem;

		p {
			width: 55%;
			overflow: auto;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		.select-all {
			position: initial;
			background-color: $color-white;
			color: $color-primary;
			width: 45%;
			white-space: nowrap;
			padding: 0;

			&.selected {
				background-color: $color-white;
			}
		}
	}

	.select-dropdown-group-label {
		width: 100%;
		padding: 3px;
		font-weight: bold;
		display: flex;
		align-items: center;
		min-height: 1rem;
	}

	.select-dropdown-group-items {
		width: 100%;

		.select-dropdown-item {
			display: block;
			// padding: 3px;
			margin: 2px;
			cursor: pointer;
		}
	}

	&.select-dropdown-multiple {
		.select-dropdown-item {
			label {
				&::before {
					content: '';
					width: 6px;
					height: 6px;
					display: inline-block;
					border: 1px solid black;
					border-radius: 6px;
					margin-right: 3px;
					padding: 2px;
					background: $color-bg;
					background-clip: content-box;
				}
			}
	
			input[type="checkbox"] {
				&:hover + label, &:checked + label {
					&::before {
						content: '';
						padding: 1px;
						border: 2px solid #f9f9f9;
						box-shadow: 0 0 0 1px black;
					}
				}
		
				&:checked + label {
					&::before { background: $color-primary; }
				}
			}
		}
	}

	.select-all {
		position: absolute;
    right: 7px;
    background-color: #d3d3d3;
    padding: 0 8px;
    cursor: pointer;
    color: #fff;
		border-radius: 3px;
		
		&.selected {
			background-color: $color-primary;
		}
	}
}