.login-form {
	@include for-mobile {
		width: 100%;
	}

	.info-polje {
		@include for-mobile {
			position: static;
			left: 0;
		}

		@include for-tablet {
			left: 105%;
		}
	}

	p {
		@include for-mobile-and-tablet {
			width: 100%;
		}
	}

	.select-wrap, i {
		@include for-mobile-and-tablet {
			width: 25%;
		}
	}

	.select-wrap {
		@include for-mobile {
			background-position-x: 50px;
			background-position: 90%;
		}

		@include for-tablet {
			background-position-x: 60px;
			background-position: 90%;
		}
	}

	input[type='text'], input[type='password'] {
		@include for-mobile-and-tablet {
			width: 75%;
		}
	}

	.sms-code {
		@include for-mobile-and-tablet {
			width: 100%;
		}

		input {
			@include for-mobile-and-tablet {
				width: 100%;
			}
		}
	}

	.sms-code-button {
		@include for-mobile-and-tablet {
			width: 100%;
		}
	}

	button {
		@include for-mobile-and-tablet {
			width: 100%;

			@include hover-active-focus {
				background: $color-text;
			}
		}
	}

	.register-btn {
		@include for-mobile-and-tablet {
			width: 100%;
			background: $color-text;
			font-weight: bold;
			font-size: 18px;
			color: $color-white;
			text-align: center;
			padding: 10px;
			margin-top: 15px;
			border-radius: 3px;
			text-transform: none;

			@include hover-active-focus {
				background: $color-primary;
			}
		}
	}

	.resend-sms-code {
		@include for-mobile-and-tablet {
			width: 100%;
		}
	}
}

.login-form-passwordless {
	@include for-mobile-and-tablet {
		width: 100%;
	}
}
