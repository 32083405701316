.vehicle-registration, .vehicle-inspection {
	@extend .registracija-korisnik-form;
	margin-top: 20px;
	width: 100%;

	flex-direction: row;

	.form-group {
			width: 22.5%;
			margin-bottom: 20px;
			margin-right: 3.3%;
			position: relative;

			label {
					display: inline-block;
			}

			&:nth-of-type(4n + 4) {
					margin-right: 0;
			}
	}
	
	&__app {
			@extend .registracija-korisnik-form;

			width: 100%;

			button {
					width: 143px;
			}
			@media only screen and (min-width: 320px) and (max-width: 479px) {
					.form-group {
							margin-right: 32px;
					}
			}

			@media only screen and (min-width: 480px) and (max-width: 767px) {
					.form-group {
							margin-right: 15px;
					}
			}
	}
}