.dodavanje-oglasa-kategorije {
	width: 100%;
	display: flex;
	flex-direction: column;

	.pretraga-item {
			text-align: center;
			margin-left: 1px;
			margin-top: 3px;
			font-size: 16px;
			height: 80px;
			padding-top: 5px;
	}

	.kategorije-pretraga-mega-menu-content-header {
			padding: 0;
			margin-top: 2px;

			p {
					text-align: center;
					color: $color-primary;

					&::after,
					&::before {
							width: 215px;
					}
			}

			&:nth-of-type(23) {

					p {
							&::before,
							&::after {
									width: 200px;
							}
					}
			}
	}

}

.dodavanje-oglasa-search {
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
}

.dodaj-oglas-content, .oglasi-tab-content-registracija {
	width: 100%;
	border: 1px solid #d9d9d9;
	padding: 20px;

	@include for-mobile {
		padding: 2px;
	}

	.heading {
		font-family: 'Tahoma';
		font-size: 18px;
		font-weight: bold;
		width: 100%;
	}

	&-category {
		display: flex;
    align-items: center;
    justify-content: space-between;

		.selectedCategory {
			width: 30%;
			font-size: 16px;
			margin-bottom: 5px;
			background: $color-text;
			height: 2.5rem;
			color: $color-white;
			padding: 9px 15px;
			overflow: hidden;
			display: inline-block;
			text-overflow: ellipsis;
			white-space: nowrap;

			@include for-mobile {
				width: 45%;
			}
		}

		a {
			font-size: 1rem;
		}
	}
}

.dodaj-oglas-content {
	float: none;
	display: flex;
	flex-direction: column;

	@include for-mobile {
		padding: 5px;
	}
}

.izaberi-kategoriju {
	flex-grow: 1;
	background: $color-text;
	color: $color-white;
	padding: 5px 10px;
	height: 37px;
	position: relative;

	i {
			font-size: 20px;
			margin-right: 5px;
	}

	p {
			display: inline-block;
			vertical-align: middle;
			font-size: 16px;
	}

	.ili {
			position: absolute;
			background: white;
			right: -13px;
			top: 50%;
			-webkit-transform: translateY(-50%);
			transform: translateY(-50%);
			width: 25px;
			height: 25px;
			line-height: 24px;
			color: black;
			text-align: center;
			border-radius: 50%;
			border: 1px solid $color-text;
			font-size: 14px;
			font-weight: bold;
	}

}

.dodavanje-oglasa-form {
	flex-grow: 15;
	input {
		width: 90%;
		height: 3rem;
		padding: .5rem;
		background: #f9f9f9;
		border: 1px solid #c0c0c0;

		@include for-mobile { width: 75%; }
	}

	button {
		width: 10%;
		padding: 3px 6px 4px 7px;
		height: 3rem;
		margin-left: -2px;
		cursor: pointer;
		background: $color-text;
		border: 0;
		color: #fff;
		font-size: 24px;

		@include hover-active-focus {
			background-color: $color-primary;
		}

		@include for-mobile { width: 25%; }
	}
}

.dodavanje-oglasa-kategorije-dropdown {
	width: 100%;
	float: left;
	border-bottom: 1px solid #c0c0c0;

	.kategorije-pretraga-mega-menu {
			border-top: 1px solid #c0c0c0;
	}
}

.dodavanje-oglasa-form-podaci {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	position: relative;

	label {
		display: block;
	}

	input[type="text"],
	input[type="number"],
	input[type="password"],
	input[type="email"],
	input[type="time"] {
		display: inline-block;
		height: 2.5rem;
		width: 100%;
		background: #fff;
		border: 1px solid #777;
		padding: 8px;
		border-radius: 20px;
	}

	input[type="radio"] {
		display: inline-block;
		position: relative;
		text-align: center;
		outline: none;
		color: #999;

		&:checked {
			color: $color-text;
		}

		&::after {
			font-family: 'fonticons';
			position: absolute;
			top: -3px;
			left: 0px;
			font-size: 60px;
			background: #fff;
		}

		&.zenski-pol-radio {
			&::after {
				content: "\E052";
			}
		}

		&.muski-pol-radio {
			&::after {
				content: "\E051";
			}
		}
	}

	.input-addon {
		background: #ccc;
    color: #101010;
    width: 40px;
    height: 40px;
    position: absolute;
    text-align: center;
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
    line-height: 40px;
	}

	margin-top: 20px;
	width: 100%;

	.form-group {
		width: 22.5%;
		margin-bottom: 20px;
		position: relative;

		label {
			display: inline-block;
		}

		.select-dropdown-picker {
			label {
				display: block;
			}
		}

		&__error-message {
			border: none;
			color: #e91c23;
		}

		@include for-mobile { width: 100%; margin-right: 0; }
		@include for-tablet { width: 48.5%; margin-right: 3.3%; }
	}

	.select-with-checkbox {
		border-radius: 4px;

		.select-dropdown {
				margin: 0;
				margin-top: -3px;
				width: 120px;
		}

		label {
				width: 100%;
				padding-top: 6px;

				&::after {
						content: '';
						font-family: 'Open Sans';
						font-size: 11px;
						color: #666;
				}

				&.no-border {
						border: none;
				}
		}
	}

	.registracija-existing-user {
		display: flex;
		flex-wrap: wrap;

		label {
			width: 100%;
		}

		.select-wrap {
			display: flex;
			align-items: center;
			justify-content: space-between;

			width: 30%;
			height: 2.5rem;
			border-top-left-radius: 20px;
			border-bottom-left-radius: 20px;
			background-position: 90%;

			cursor: pointer;

			label {
				width: auto;
				margin-left: 5px;
			}
		}

		.dial-select {
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
			opacity: 0;
		}

		input[type="text"] {
			width: 70%;
			padding: 0 5px;
			border-left: 0;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}

	.tip-oglasa-naziv {
		@include for-mobile { font-size: 1rem; line-height: 1.25rem; }
	}

	.tip-oglasa-cena {
		@include for-mobile { font-size: 2.5rem; }
	}

	.tip-oglasa-lista {
		@include for-mobile { min-height: 0; }
	}

	.tip-oglasa-item {
		a {
			@include for-mobile {
				width: 85%;
				padding: .5rem;
				font-size: 1rem;
				text-align: center;
				margin-top: auto;
			}
		}

		.tip-oglasa-lista {
			li {
				@include for-mobile { margin-bottom: 5px; }
			}
		}
	}
}

.choose-form-style {
	display: flex;
	width: 100%;
	justify-content: space-between;

	h4 {
		display: inline-block;
		margin: 0px 2px 0px 8px;

		@include for-mobile {
			width: 75%;
			font-size: 14px;
		}
	}

	input[type="radio"] {
		display: none;
	}

	input[type="radio"] + label {
		display: inline-block;
		-webkit-appearance: none;
		background-color: #e6e1e1;
		border: 5px solid white;
		border-radius: 25px;
		width: 100%;
		position: relative;
		width: 25px;
		height: 25px;

		@include for-mobile {
			margin-right: auto;
		}
	}

	input[type="radio"]:checked + label {
		background: #e91c23;
	}
}

.choose-form-style-item {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 10px;
	background: #f9f9f9;
	border: 1px solid #d9d9d9;
	cursor: pointer;

	@include for-mobile {
		flex-wrap: wrap;
		justify-content: flex-end;
		padding: 5px;
	}

	&:hover, &.active {
		background-color: $color-primary;
		color: white;
	}
}

.ads-add {
	&__swap-row, &__compatibility-row {
		width: 100%;
		display: flex;
		@include for-mobile { flex-wrap: wrap; }

		.form-group {
			margin-right: 2.5%;
			@include for-mobile { margin-right: 0; }
		}

		&_first {
			justify-content: space-between;
			width: 74%;
			@include for-mobile { width: 100%; }

			.form-group {
				width: 30%;
				@include for-mobile { width: 100%; }
			}
		}

		&_loading {
			width: 74%;
			position: relative;
			display: inline-block;
			min-height: 68px;
			float: left;
		}
	}

	&__location {
		&-wrapper {
			width: 100%;
			display: flex;
			flex-wrap: nowrap;
		}

		@include for-mobile {
			&-wrapper { flex-wrap: wrap; }
		}

		.form-group {
			margin-bottom: 20px;
			margin-right: 26px !important;
			position: relative;

			&:last-of-type {
				margin-right: 0 !important;
			}

			label {
				display: block;
			}

			select {
				width: 100%;
			}

			@include for-mobile { margin-right: 0 !important; }
			@include for-tablet {
				width: 30%;
			}
		}
	}

	&__image-uploader {
			.ads-add__rotate-btn, .ads-add__remove-btn {
					cursor: pointer;
			}

			.ads-add__hide-btn {
					cursor: pointer;
					font-size: 14px;
			}
	}

	&__select {
			optgroup {
					font-family: 'Calibri';
					color: #a395a4;
					font-weight: 300;
			}

			option {
					font-family: 'Calibri';
					color: black;
			}
	}

	&__submit {
			margin-top: 10px;

			&:disabled {
					background-color: #999999;
					cursor: not-allowed;
			}
	}

	&__pre-specifications, &__post-specifications {
		width: 100%;
		display: flex;
		flex-wrap: wrap;

		.form-group {
			margin-right: 3.3%;

			@include for-laptop-and-desktop {
				&:nth-of-type(4n + 4) {
					margin-right: 0;
				}
			}

			@include for-mobile { margin-right: 0; }
			@include for-tablet {
				margin-right: 3%;

				&:nth-of-type(2n + 2) {
					margin-right: 0;
				}
			}
		}

		&-app {
			.form-group {
				width: 100%;
				margin-right: 0;
			}
		}
	}

	&__stars-specifications {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
	}

	&__compatibilities {
			display: flex;
			flex-wrap: wrap;

			.form-group {
				margin-right: 26px;
				@include for-mobile { margin-right: 0; }
			}

			.dodatna-oprema-checkbox {
				margin-left: 0;

				@include for-mobile { width: 100%; }
			}

			.products-years {
				.form-group:nth-of-type(5n+5) {
					margin-right: 26px;
				}

				width: 100%;
				display: flex;
				flex-direction: column;

				&-first-column {
					display: flex;
					flex-wrap: wrap;

					button {
						height: 35px;
						width: 22.5%;
						margin-top: 1.1rem;
						margin-left: 24px;
						border: 1px solid #d9d9d9;
						border-radius: .2rem;
						text-transform: uppercase;
						background-color: $color-white;
						font-family: 'Trebuchet MS';
						font-size: 18px;
						font-weight: bold;
						color: $color-text;
						cursor: pointer;

						@include hover-active-focus {
							background: $color-primary;
							color: $color-white;
						}
					}
				}

				&-second-column {
					.form-group:nth-of-type(4n+4) {
						margin-right: 0;
					}
				}
			}
	}

	&__phone-field {
			padding-left: 43px !important;
	}

	&__photos-list-view {
			display: flex;
			flex-direction: column;
			width: 100%;
	}

	&__photos-list-view-item {
			display: flex;
			align-items: center;
			height: 140px;
			margin: 5px;
			box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
			justify-content: space-between;

			&.hovered {
					box-shadow: 0 3px 6px rgba($color-secondary, 0.16), 0 3px 6px rgba($color-secondary, 0.23);
			}
	}

	&__photos-list-view-item-img {
			width: 173px;
			height: 130px;
			margin: 5px;
			background: no-repeat center center;
			background-size: cover;
	}

	&__photos-list-view-item-img-icon-container {
			width: 24px;
			height: 24px;
			position: relative;
	}

	&__photos-list-view-item-img-icon {
			@include absolute-center;
			top: 40%;

			&.primary {
					color: $color-white;
			}

			&.secondary {
					color: $color-text;
			}
	}

	&__photos-list-view-item-arrows {
			height: 187px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin-right: 10px;
	}
}

.ads-add__price-description {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@include for-mobile {
		flex-wrap: wrap;
	}

	.left-inputs {
		width: 22.5%;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;

		.form-group {
			width: 100%;
		}

		@include for-mobile {
			width: 100%;
		}
	}

	.form-group-textarea {
		width: 65%;

		@include for-mobile {
			width: 100%;
		}

		.textarea-form-group {
			width: 100%;

			&.form-group_error {
				textarea {
					border-color: red;
				}
			}
		}

		textarea {
			background: #f9f9f9;
			border: 1px solid #c0c0c0;
			border-radius: 3px;
			padding: 8px;
			width: 100%;
			height: 178px;
			font-family: 'Calibri';
			resize: none;

			@include for-mobile {
				height: 5rem;
			}
		}
	}
}

.kontakt-podaci {
	width: 100%;

	border: 1px solid #c0c0c0;

	.verifikacija-holder {
			float: left;
			width: 100%;
			display: none;

			.verifikacija {
					float: left;
					width: 100%;
					background: $color-secondary;
					margin-bottom: 10px;
					border-radius: 4px;
					padding: 10px;
					text-align: center;
					color: $color-white;
			}

			.verifikacija-action {
					float: left;
					width: 100%;

					input, button {
							width: 49%;
							float: left;
							text-align: center;
							margin: 0;
					}

					input {
							margin-right: 2%;
							border: 1px solid $color-text;
							padding: 9px;
					}

					button {
							font-size: 14px;
					}

			}

	}

	&.uspesno-poslato {
			.verifikacija-holder {
					display: block;

					.btn-oglas-submit {
							display: block;
					}
			}

			.btn-oglas-submit {
					display: none;
			}

	}

	.kontakt-podaci-header {
			font-family: 'Trebuchet MS';
			text-transform: uppercase;
			font-weight: bold;
			text-align: center;
			float: left;
			width: 100%;

			a {
					width: 100%;
					float: left;
					background: #f9f9f9;
					padding: 10px;
					color: $color-text;
					border-bottom: 1px solid #c0c0c0;

					@include hover-active-focus {
							background: #f1f1f1;
					}

					&.active {
							background: $color-white;
							border-bottom: $color-white;
					}

			}
	}

	.kontakt-podaci-content {
		padding: 20px;
		width: 100%;
		display: none;

		&>div {
				width: 100%;
				padding-top: 5px;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
		}

		&.active {
				display: block;
		}

		.form-group-login {
				&.form-group_error {
						input {
								border-color: red;
						}
				}

				label {
						margin-bottom: 4px;
						display: block;
				}

				input {
						border-radius: 0;
						background: $color-white;
						border: 1px solid #dcdae5;
				}

				span {
						color: $color-primary;
				}

				span.required {
						color: #e91c23
				}

				i {
						width: 37px;
						height: 34px;
						margin-top: -2px;
						text-align: center;
						font-size: 18px;
						line-height: 31px;
						border: 1px solid #dcdae5;
						border-right: 0;
				}
		}
	}

	.form-group {
		width: 32%;

		input, select {
			width: 100%;
		}

		&:nth-of-type(2), &:last-of-type {
			margin-right: 34%;
			@include for-mobile-and-tablet { margin-right: 0; }
		}

		@include for-mobile { width: 100%; }
		@include for-tablet { width: 50%; }
	}

	.select-with-checkbox {
		float: left;

		input {
			width: auto;
		}

		label {
			width: 182px;
		}

		.select-dropdown {
			width: 180px;
		}
	}
}

.dodatna-oprema-checkbox {
	float: left;
	margin-bottom: 20px;
	width: 210px;

	&.dodatna-oprema-checkbox-fullWidth {
			width: 100%;
	}

	label {
			display: inline-block;
			vertical-align: top;
	}

}

.radio-form-group {
	float: left;
	width: 100px;

	input[type="radio"] {
		border-radius: 50%;
		background: transparent;
		cursor: pointer;
		display: inline-block;
		border: 1px solid #c0c0c0;
		width: 18px;
		height: 18px;
		position: relative;
		-webkit-appearance: none;
		vertical-align: middle;

		&::after {
			position: relative;
			background: #c0c0c0;
			border-radius: 50%;
			content: '';
			display: block;
			left: 3px;
			top: 3px;
			height: 10px;
			width: 10px;
		}

		&:checked {
			&::after {
				background: $color-primary;
			}
		}
	}

	label, p {
			display: inline-block;
			vertical-align: middle;
			cursor: pointer;
	}

	p {
			margin-left: 3px;
	}
}
